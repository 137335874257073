/* eslint-disable react/no-unstable-nested-components */
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError';
import { getEquipmentsByUser } from 'helpers/api/equipments';
import { generateReport } from 'helpers/api/reports';
import usePosiStopReport from 'helpers/hooks/usePosiStopReport';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { worldClockTimezones } from 'helpers/const/timezones';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

export default function Reports({ show, handleClose, multi }) {
	const { generatePosiStopReport, downloadPosiStopReportExcel } =
		usePosiStopReport();
	const [isLoading, setIsLoading] = useState(false);
	const [showLink, setShowLink] = useState(false);
	const [reportLink, setReportLink] = useState('');
	const [, setReportOpts] = useState({});
	const [equipment, setEquipment] = useState([]);
	const { id } = useParams();

	const [isReportWeekly, setIsReportWeekly] = useState(false);
	const [isReportDaily, setIsReportDaily] = useState(false);

	const [startDate, setStartDate] = useState(moment().startOf('week').toDate());
	const defaultDate = moment().subtract(1, 'days').toISOString().slice(0, 10);
	const defaultStartTime = '06:00';
	const defaultEndTime = '18:00'; // this is 6 PM

	const schema = yup
		.object({
			reportType: yup.string(),
			equipment: yup.string().required('Equipment is required'),
			date: yup.string().required('Date is required'),
			timeStarts: yup.string().required('Start time is required'),
			timeEnds: yup
				.string()
				.required('End time is required')
				.test(
					'time-end-after-time-start',
					'End time must be after start time',
					(value, ctx) => ctx.parent.timeStarts < value,
				),
			reportInfo: yup.string(),
			reportFileType: yup.string(),
		})
		.required();

	const { register, handleSubmit, formState, reset, setValue } = useForm({
		resolver: yupResolver(schema),
	});

	const { errors } = formState;

	const openReport = (reportSrcLink) => {
		window.open(reportSrcLink, '_blank');
	};

	/**
	 * Generates a weekly Posi-Stop report based on the provided data.
	 *
	 * @param {Object} data - The data object containing the report parameters.
	 * @param {string} data.reportType - The type of the report (e.g. 'week').
	 * @param {string} data.date - The date for the report, formatted as 'DD/MM/yyyy'.
	 * @param {string} data.equipment - The equipment ID.
	 * @param {string} data.timeStarts - The start time for the report.
	 * @param {string} data.timeEnds - The end time for the report.
	 * @param {string} data.reportInfo - Additional information about the report.
	 * @param {string} data.reportFileType - The file type for the report.
	 * @returns {Promise<void>} - A promise that resolves when the report is generated.
	 */
	const handleGenerateReportWeekly = async (data) => {
		toast.dismiss();
		toast.loading('Generating report...');
		try {
			const params =
				data.reportType === 'week'
					? {
							...data,
							date: moment(data.date, 'DD/MM/yyyy').format('yyyy-MM-DD'),
						}
					: data;
			// Update the daily type to day as per request type.
			// params.reportType = data.reportType === 'daily' ? 'day' : 'week';;
			const responseData = await generatePosiStopReport(params);
			if (responseData.data.generatePosiStopReport.length > 0) {
				// Prepare data for excel report
				const columns = [
					'Equipment ID',
					'Date (DD-MMM-YYYY)',
					'Time',
					'Event',
					// 'CODES',
				];
				const rows = [];
				responseData?.data?.generatePosiStopReport?.forEach((element) => {
					rows.push({
						'Equipment ID': element.serial || '',
						'Date (DD-MMM-YYYY)':
							moment(element.dateTime, 'DD-MM-YYYY HH:mm:ss').format(
								'DD-MMM-YYYY',
							) || '',
						Time:
							moment(element.dateTime, 'DD-MM-YYYY HH:mm:ss').format('HH:mm') ||
							'',
						Event: element.event || '',
						// CODES: element.description || '',
					});
				});
				await downloadPosiStopReportExcel(
					rows,
					columns,
					'Posi-Stop Report',
					'Posi-Stop Status',
				);
				setIsLoading(false);
				handleClose();
				toast.remove();
			} else {
				toast.remove();
				setIsLoading(false);
				toast.error('No data found');
			}
		} catch (error) {
			setIsLoading(false);
			toast.remove();
			toast.error(error.message);
		}
	};

	/**
	 * Handles the generation of a report based on the provided data.
	 *
	 * @param {Object} data - The data required to generate the report.
	 * @returns {Promise} - A promise that resolves with the generated report data.
	 */
	const handleGenerateReport = (data) => {
		setShowLink(false);
		handleClose();
		const promise = generateReport(data);
		toast.promise(
			promise,
			{
				loading: 'Generating report...',
				success: (responseData) => {
					const resData = responseData.data.generatePDF;
					setIsLoading(false);
					setReportLink(resData);
					setShowLink(true);
					return (
						<div>
							<h6>Report generated successfully!</h6>
							<p>Click the link below to view the report.</p>
							<a
								href={resData.path}
								target="_blank"
								rel="noreferrer"
								style={{
									textDecoration: 'none',
									color: '#00834d',
								}}
							>
								OPEN Report
							</a>
						</div>
					);
				},
				error: () => {
					setIsLoading(false);
					return 'Error generating report';
				},
			},
			{
				loading: {
					position: 'top-right',
				},
				error: {
					position: 'top-right',
				},
				success: {
					duration: 8000,
					icon: '',
					position: 'top-right',
				},
			},
		);
		setReportOpts(data);
	};

	const reportSubmit = (data) => {
		setIsLoading(true);
		try {
			// If Weekly or Daily Report is true, generate a weekly or daily report of events.
			if (isReportWeekly || isReportDaily) handleGenerateReportWeekly(data);
			else handleGenerateReport(data);
		} catch (error) {
			toast.error(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const getEquipment = () => {
		getEquipmentsByUser().then((res) => {
			setEquipment(res.data.equipments);
		});
	};

	useEffect(() => {
		getEquipment();
		reset();
		setShowLink(false);
		setIsReportWeekly(false);
		setIsReportDaily(false);
	}, [show]);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			centered
			className="profile-modal"
			size="lg"
		>
			<Modal.Body>
				<Row>
					<Form onSubmit={handleSubmit(reportSubmit)}>
						<div className="information-container">
							<span className="information">Generate Report</span>
						</div>
						<Row className="inputs">
							<Col lg={6} sm={12} className="mb-3">
								<span>Report Type</span>
								<select
									id="reportType"
									name="reportType"
									{...register('reportType')}
									onChange={(e) => {
										const { value } = e.target;
										setIsReportWeekly(value === 'week');
										setIsReportDaily(value === 'daily');
										setValue('reportType', value);
										setValue(
											'date',
											value === 'week'
												? moment().startOf('week').format('DD/MM/yyyy')
												: defaultDate,
										);
									}}
								>
									{/* <option value="">--Select Type--</option> */}
									<option value="day">Daily Report</option>
									<option value="daily">Daily Event Report</option>
									<option value="week">Weekly Event Report</option>
									<option value="daySummary" disabled hidden>
										Daily Summary
									</option>
									<option value="weekSummary" disabled hidden>
										Weekly Summary
									</option>
								</select>
								<FormError>{errors?.reportType?.message}</FormError>
							</Col>
							{multi ? (
								<Col lg={6} sm={12} className="mb-3">
									<span>Equipment</span>
									<select
										id="equipment"
										name="equipment"
										{...register('equipment')}
									>
										<option value="">--Select Equipment--</option>
										{equipment.map((eq) => (
											<option value={eq.serial} key={eq.id}>
												{eq.serial}
											</option>
										))}
									</select>
									<FormError>{errors?.equipment?.message}</FormError>
								</Col>
							) : (
								<Col lg={6} sm={12} className="mb-3">
									<span>Equipment</span>
									<input
										type="text"
										{...register('equipment')}
										defaultValue={id}
										readOnly
									/>
								</Col>
							)}
							<Col lg={6} sm={12} className="mb-3">
								{isReportWeekly ? (
									<>
										<span>Starting Date of week</span>
										<DatePicker
											selected={startDate}
											onChange={(date) => {
												setValue('date', moment(date).format('DD/MM/yyyy'), {
													shouldValidate: true,
												});
												setStartDate(date);
											}}
											dateFormat="dd/MM/yyyy"
											// locale="en-GB"
											maxDate={moment().subtract(1, 'days').toDate()}
											showWeekNumbers
											showWeekPicker
										/>
									</>
								) : (
									<>
										<span>Date</span>
										<Form.Control
											type="date"
											{...register('date')}
											defaultValue={defaultDate}
											max={moment()
												.subtract(1, 'days')
												.toISOString()
												.slice(0, 10)}
										/>
										<FormError>{errors?.date?.message}</FormError>
									</>
								)}
							</Col>
							{(isReportWeekly || isReportDaily) && (
								<Col lg={6} sm={12} className="mb-3">
									<label htmlFor="timezone">Timezone</label>
									<select
										id="timezone"
										name="timezone"
										{...register('timezone')}
										defaultValue="America/Chicago"
									>
										<option value="">--Select Timezone--</option>
										{worldClockTimezones?.map((tz) => (
											<option key={tz.value} value={tz.value}>
												{tz.value} {tz.label}
											</option>
										))}
									</select>
								</Col>
							)}
							{/* <Col lg={6} sm={12} className="mb-3">
								<span>Report Info</span>
								<FormError>{errors?.reportInfo?.message}</FormError>
								<select
									id="reportInfo"
									name="reportInfo"
									{...register('reportInfo')}
								>
									<option value="">--Select Report Type--</option>
									<option id="reportTypeBoth" value="both">
										Rig &amp; Engine Report
									</option>
									<option id="reportTypePosi" value="posi">
										Rig Report
									</option>
									<option id="reportTypeCan" value="can">
										Engine Report
									</option>
								</select>
							</Col> */}
							{/* <Col lg={6} sm={12} className="mb-3">
								<span>Export Type</span>
								<FormError>{errors?.reportFileType?.message}</FormError>
								<select
									id="reportFileType"
									name="reportFileType"
									{...register('reportFileType')}
								>
									<option value="">--Select Export Option--</option>
									<option id="reportTypePDF" value="PDF">
										Export to PDF (Rig Details)
									</option>
									<option id="reportTypeCSV" value="CSV">
										Export to CSV (Raw Data)
									</option>
								</select>
							</Col> */}
							<Col lg={6} sm={12} className="mb-3">
								<span>Time range</span>
								<Row>
									<Col lg={6} sm={6}>
										<input
											type="time"
											{...register('timeStarts')}
											defaultValue={defaultStartTime}
										/>
										<FormError>{errors?.timeStarts?.message}</FormError>
									</Col>
									<Col lg={6} sm={6}>
										<input
											type="time"
											{...register('timeEnds')}
											defaultValue={defaultEndTime}
										/>
										<FormError>{errors?.timeEnds?.message}</FormError>
									</Col>
								</Row>
							</Col>
						</Row>

						<div className="d-flex float-end">
							<Button
								variant="bordered-green"
								className="me-3"
								onClick={handleClose}
							>
								Close
							</Button>
							<Button type="submit" variant="primary-green">
								{isLoading ? (
									<Spinner size="sm" animation="border" />
								) : (
									'Request Report'
								)}
							</Button>
							{showLink && (
								<Button
									variant="bordered-green"
									className="me-3"
									style={{ marginLeft: 10 }}
									onClick={() => openReport(reportLink)}
									autoFocus
								>
									Open Report
								</Button>
							)}
						</div>
					</Form>
				</Row>
			</Modal.Body>
		</Modal>
	);
}
