import { multicamfeed } from 'helpers/api';
import { useAuth } from 'helpers/contexts/AuthContext';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

export default function useCameraLayout() {
	const { user } = useAuth();
	const [cameraLayouts, setCameraLayouts] = useState([]);

	const { data, isLoading, refetch } = useQuery(
		`multi-cameras-layouts-${user.userid}`,
		() => multicamfeed.getCamerasLayoutList(),
		{
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		setCameraLayouts(data?.data?.getMultiViewLayout || []);
		return () => setCameraLayouts([]);
	}, [data]);

	const updateCameraLayout = async (params) => {
		toast.loading('Updating Cameras...');
		try {
			const result = await multicamfeed.updateCameraLayout(params);
			if (result.data.updateMultiViewLayout.success) {
				await refetch();
				toast.remove();
				toast.success('Cameras updated');
			} else {
				toast.remove();
				toast.error('Error Updating Cameras');
			}
		} catch (error) {
			toast.error('Error Updating Cameras');
		}
	};

	const createCameraLayout = (params) =>
		toast.promise(multicamfeed.addCameras(params), {
			loading: 'Adding Cameras...',
			success: () => {
				refetch();
				return 'Cameras Added';
			},
			error: 'Error Adding Cameras',
		});

	const deleteCameraLayout = (id) =>
		toast.promise(multicamfeed.deleteCameraLayout(id), {
			loading: 'Deleting Camera layout...',
			success: () => {
				refetch();
				return 'Camera layout deleted.';
			},
			error: 'Error Deleting Cameras layout.',
		});

	return {
		cameraLayouts,
		setCameraLayouts,
		isLoading,
		refetch,
		updateCameraLayout,
		createCameraLayout,
		deleteCameraLayout,
	};
}
