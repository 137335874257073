import { Modal, Form, Button, Accordion, Card } from 'react-bootstrap';
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormError from 'components/FormError';
import { useAuth } from 'helpers/contexts/AuthContext';
import { resetPassword } from 'helpers/api/userManagement';
import toast from 'react-hot-toast';
import {
	EquimentSelector,
	Yard,
	SelectAllRegion,
	AccordionTitle,
} from 'pages/alert-management/styled';

export default function AlertEquipmentListPopup({
	equipmentList,
	timezones,
	saved,
	show,
	category,
	handleClose,
}) {
	const { user } = useAuth();
	const formData = JSON.parse(localStorage.getItem('userAlerts'));
	let selectEq;
	const schema = yup
		.object({
			userid: yup.string().default(user?.userid),
			oldpassword: yup.string().required('Old Password is required'),
			newpassword: yup.string().required('New Password is required'),
			confirm: yup.string().required(''),
		})
		.required();

	const selectEquipment = (e) => {
		// If e is a serial get the correct values
		try {
			const cat = category.name;
			const btn = e.target.closest('.card');
			const serial = btn.getAttribute('eventkey');
			// Check if it was selected or not
			const selected =
				selectEq !== undefined ? selectEq : !btn.classList.contains('selected');
			// Add alert to alertLisdt for equipment
			// Find or add equipment
			const foundIndex = formData.findIndex((el) => serial === el.serial);
			if (foundIndex === -1) {
				// Add equipment
				formData.push({
					serial,
					alertList: [{ name: cat, sms: true, email: true, push: true }],
				});
			} else {
				// Update equipment
				const aLIndex = formData[foundIndex].alertList.findIndex(
					(al) => al.name === cat,
				);
				if (aLIndex === -1) {
					// Add category
					formData[foundIndex].alertList.push({
						name: cat,
						sms: true,
						email: true,
						push: true,
					});
				} else {
					// if(!selected && formData[foundIndex].alertList.length === 1){
					// 	delete formData[foundIndex];
					// }
					// else{
					formData[foundIndex].alertList[aLIndex] = {
						name: cat,
						sms: selected,
						email: selected,
						push: selected,
					};
					// }
				}
			}
			localStorage.setItem('userAlerts', JSON.stringify(formData));
			// Select or deselect
			btn.classList.remove('selected');
			if (selected) btn.classList.add('selected');
			const eqbtn = document.querySelectorAll(`.card`);
			const eqsel = document.querySelectorAll(`.card.selected`);
			const catsel = document.querySelector(`[data-category='${cat}']`);
			catsel.checked = eqbtn.length === eqsel.length;
		} catch (err) {
			toast.error(`Unable to select equipment: ${err.message}`);
		}
		return true;
	};
	const selectAllYard = (e) => {
		const btns = e.target.closest('.project').nextElementSibling.children[0];
		const serials = btns.querySelectorAll('.card');
		const serialsSelected = btns.querySelectorAll('.selected');
		serials.forEach((el) => {
			// selectEquipment(el.childNodes[0].click(), (serials.length !== serialsSelected.length))
			selectEq = serials.length !== serialsSelected.length;
			el.childNodes[0].click();
		});
		selectEq = undefined;
	};

	/**
	 * Selects all equipment in the current region.
	 * This function is triggered when the "Select All" button is clicked. It iterates over all the equipment cards in the current region and clicks on each one to select them. It also updates the state of the "Select All" checkbox to match the selection state of the equipment cards.
	 * @param {Event} e - The click event object.
	 */
	const selectAllRegion = (e) => {
		const btns = e.target.nextElementSibling.children[0];
		const serials = btns.querySelectorAll('.card');
		const serialsSelected = btns.querySelectorAll('.selected');
		serials.forEach((el) => {
			// selectEquipment(el.childNodes[0].click(), (serials.length !== serialsSelected.length))
			selectEq = serials.length !== serialsSelected.length;
			el.childNodes[0].click();
		});
		selectEq = undefined;
	};

	const handleSelectAllEquips = (e) => {
		const btns = document.querySelectorAll('.select-all-region-selector');
		btns?.forEach((subSelectButton) => {
			const subBtns = subSelectButton.nextElementSibling.children[0];
			toggleSubEquipmentsByElement(subBtns, true);
		});
	};

	/**
	 * Deselects all equipment cards within the specified containers.
	 * This function is triggered when the "Deselect All" button is clicked. It iterates over all the "select-all-region-selector" buttons and their corresponding equipment card containers, and deselects all the equipment cards within those containers.
	 * @param {Event} e - The click event object.
	 */
	const handleDeselectAllEquips = (e) => {
		const btns = document.querySelectorAll('.select-all-region-selector');
		btns?.forEach((subSelectButton) => {
			const subBtns = subSelectButton.nextElementSibling.children[0];
			toggleSubEquipmentsByElement(subBtns, false);
		});
	};

	/**
	 * Toggles the selection state of all equipment cards within the specified container.
	 *
	 * @param {HTMLElement} btns - The container element that holds the equipment cards.
	 * @param {boolean} check - If true, all equipment cards will be selected. If false, all equipment cards will be deselected.
	 */
	const toggleSubEquipmentsByElement = (btns, check) => {
		const serials = btns.querySelectorAll('.card');
		const serialsSelected = btns.querySelectorAll('.selected');
		serials.forEach((el) => {
			selectEq = serials.length !== serialsSelected.length;
			// Check All equipments if not selected.
			if (selectEq && check) el.childNodes[0].click();
			// If Click on De Select All Button
			if (check === false) {
				const item = el.childNodes[0].closest('.card');
				// If item is already selected.
				if (item.classList.contains('selected')) {
					// Click to deselect
					// BUG: Somehow direct item.click() is not working so I put it inside timeout which is run another event loop. And it's works.
					setTimeout(() => {
						item.click();
					});
				}
			}
			// else {
			// 	if (check === false && selectEq === false) {
			// 		el.childNodes[0].click();
			// 	}
			// }
			// else if(check === false) el.childNodes[0].click();
			// if (check === false) el.childNodes[0].click();
		});
		selectEq = undefined;
	};

	return (
		<Modal
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={show}
			onHide={handleClose}
			className="profile-modal"
		>
			<Modal.Body>
				<div className="mb-1 information-container">
					<span className="information">{category.desc}</span>
					<h5>Select Equipment</h5>
				</div>
				<div className="flex justify-end w-full gap-2 mt-2 mb-2 sm:mb-2 md:mt-0 ">
					<Button
						type="button"
						variant="bordered-green"
						className=""
						onClick={handleSelectAllEquips}
					>
						Select All
					</Button>
					<Button
						type="button"
						variant="bordered-red"
						className=""
						onClick={handleDeselectAllEquips}
					>
						Deselect All
					</Button>
				</div>
				<Form
					method="post"
					className="d-grid g-1"
					style={{ maxHeight: '55vh', overflow: 'auto' }}
				>
					<div className="space-y-4 alert-list-view">
						{timezones?.map((zone) => (
							<Accordion defaultActiveKey={zone.name} key={zone.name}>
								<Accordion.Item
									eventKey={zone.name}
									key={zone.name}
									className="w-100"
									style={{ position: 'relative' }}
								>
									<AccordionTitle className="w-10/12 lg:w-10/12 xl:w-11/12">
										<Accordion.Header>
											<h6 className="font-semibold">
												{zone.reg[0]} <small>{zone.name}</small>
											</h6>
										</Accordion.Header>
									</AccordionTitle>
									<SelectAllRegion
										onClick={selectAllRegion}
										className="select-all-region-selector border-2 w-2/12 lg:w-2/12 xl:w-1/12 border-gray-300 hover:border-skin-primary hover:text-skin-primary rounded shadow-sm hover:shadow-lg  h-12 my-0.5 flex justify-center items-center duration-200"
									>
										Select All
									</SelectAllRegion>
									<Accordion.Body className="p-0">
										{/* Project */}
										{zone.yards.map((y) => (
											<React.Fragment key={y}>
												<Yard className="project" onClick={selectAllYard}>
													<div className="flex-wrap d-flex">
														<h5 className="mb-0">{y}</h5>
													</div>
												</Yard>
												{/* Equipments */}
												<EquimentSelector className="equipmentGroup">
													<Accordion className="grid w-full grid-flow-row grid-cols-2 gap-2 p-2 equipment md:grid-cols-4 lg:grid-cols-6">
														{equipmentList
															?.filter((item) => item?.yard?.id === y)
															.map((eq) => {
																const selected = formData?.find((item) => {
																	if (item?.serial === eq?.serial) {
																		const alertList = item?.alertList;
																		return alertList?.find(
																			(al) =>
																				al.name === category.name &&
																				((al.sms && al.sms === true) ||
																					(al.email && al.email === true)),
																		);
																	}
																	return '';
																});
																const selectedClass =
																	selected !== undefined ? 'selected' : '';
																return (
																	// <div
																	// 	className={`${selectedClass} border-2 border-gray-300 bg-gray-50/50 shadow-sm rounded col-span-2 hover:shadow px-4 h-10 flex items-center cursor-pointer text-skin-primary font-medium`}
																	// 	key={eq.serial}
																	// 	onClick={selectEquipment}
																	// >
																	// 	{eq.serial}
																	// </div>

																	<Card
																		id={eq.serial}
																		eventkey={eq.serial}
																		key={eq.serial}
																		className={`${selectedClass} hover:shadow`}
																		onClick={selectEquipment}
																	>
																		<Card.Header>
																			<h6
																				className="font-medium duration-300 select-none align-self-center color-primary toggleLabel"
																				style={{
																					paddingLeft: 2,
																					paddingTop: 2,
																				}}
																			>
																				{eq.serial}
																			</h6>
																		</Card.Header>
																	</Card>
																);
															})}
													</Accordion>
												</EquimentSelector>
											</React.Fragment>
										))}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						))}
					</div>

					{/* {equipmentList?.map?.((eq) => (
						<div className="px-1" key={eq?.serial}>
							<h5>{eq?.serial}</h5>
						</div>
					))} */}
				</Form>
				<div className="mt-3 button-wrapper ms-auto">
					<Button
						type="button"
						variant="bordered-green"
						className="me-3"
						onClick={handleClose}
					>
						Close
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
