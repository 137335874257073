import { DashboardContextProvider } from 'pages/dashboard/DashboardContext';
import CameraLayoutList from './CameraLayoutList';

export default function () {
	return (
		<DashboardContextProvider>
			<CameraLayoutList />
		</DashboardContextProvider>
	);
}
