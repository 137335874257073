import { yupResolver } from '@hookform/resolvers/yup';
import { auth } from 'helpers/api';
import { useAuth } from 'helpers/contexts/AuthContext';
import { LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY } from 'helpers/utils/auth-provider';
import ForceLoginConfirmModel from 'pages/login/ForceLoginConfirmModel';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import {
	CustomFormCheck,
	Forgot,
	Input,
	LoginButton,
	LoginFormContainer,
} from './login.styled';

const schema = yup
	.object({
		username: yup
			.string()
			.required('Username is required.')
			.max(100, 'Please check the length'),
		password: yup
			.string()
			.required('Password is required.')
			.max(100, 'Please check the length'),
	})
	.required();

function LoginForm() {
	const { login, user, logout } = useAuth();
	const navigate = useNavigate();
	const [showLoginConfirm, setShowLoginConfirm] = useState(false);
	const [forceLoading, setForceLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const getRememberMeCredentials = () => {
		try {
			const crypt = window.atob(
				localStorage.getItem(LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY) || '',
			);
			if (crypt) {
				const credentials = JSON.parse(crypt);
				if (credentials && credentials.rememberMe === true) {
					reset(credentials);
				}
			}
		} catch (err) {
			toast.error(err.message);
		}
	};

	/**
	 * Fetches the device ID of the current device, either from localStorage or by making a request to ipify.org to get the device's IP address.
	 *
	 * @returns {string} The device ID, either retrieved from localStorage or fetched from ipify.org.
	 */
	const fetchIp = async () => {
		let deviceId = localStorage.getItem('deviceId');
		if (!deviceId) {
			const response = await fetch('https://api.ipify.org?format=json');
			const data = await response.json();
			deviceId = data.ip;
			localStorage.setItem('deviceId', deviceId);
		}
		return deviceId;
	};

	useEffect(() => {
		getRememberMeCredentials();
		if (user) {
			auth
				.getCurrentUser()
				.then(() => {
					navigate('/dashboard');
				})
				.catch(() => {
					logout();
					navigate('/');
				});
		}
	}, [user]);

	/**
	 * Handles the login process, including remembering the user's login credentials and fetching the device ID.
	 *
	 * @param {Object} data - The login data, including the username, password, and a flag to indicate whether to remember the login credentials.
	 * @param {boolean} [forceLogin=false] - A flag to indicate whether to force the login, even if another device is already logged in.
	 * @returns {Promise<void>} - A promise that resolves when the login process is complete.
	 */
	const handleLogin = async (data, forceLogin = false) => {
		let rememberedData = '';
		if (data && data.rememberMe === true) {
			rememberedData = JSON.stringify(data);
		}
		localStorage.setItem(
			LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY,
			window.btoa(rememberedData),
		);
		const deviceId = await fetchIp();
		const loginResponse = await login({
			uname: data.username,
			upassword: data.password,
			deviceId,
			forceLogin,
		});

		if (loginResponse?.hasOtherDeviceLoggedIn) {
			setShowLoginConfirm(true);
		}
	};

	/**
	 * Handles the login process when the login form is submitted.
	 *
	 * @param {Object} data - The login data, including the username and password.
	 * @returns {Promise<void>} - A promise that resolves when the login process is complete.
	 */
	const onSubmit = async (data) => {
		try {
			setIsLoading(true);
			await handleLogin(data, false);
		} catch (err) {
			toast.error(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * Handles the forced login process, including remembering the user's login credentials and fetching the device ID.
	 *
	 * @async
	 * @function handleForceLogin
	 * @returns {Promise<void>} - A promise that resolves when the forced login process is complete.
	 */
	const handleForceLogin = async () => {
		try {
			setForceLoading(true);
			const data = getValues();
			await handleLogin(data, true);
			setShowLoginConfirm(false);
		} catch (err) {
			toast.error(err.message);
		} finally {
			setForceLoading(false);
		}
	};

	const ErrorMessage = ({ children }) => (
		<small className="text-danger mt-2 d-block">{children}</small>
	);

	return (
		<div>
			{showLoginConfirm === true ? (
				<ForceLoginConfirmModel
					show={showLoginConfirm}
					setShow={setShowLoginConfirm}
					isLoading={forceLoading}
					handleForceLogin={handleForceLogin}
				/>
			) : (
				<LoginFormContainer onSubmit={handleSubmit(onSubmit)}>
					{/* <NoticeMessage>
					Notice: Site is under maintenance.
				</NoticeMessage>
				<br /> */}
					<Row>
						<Col md={12}>
							<Form.Group className="mb-3">
								<Input
									type="text"
									placeholder="User ID"
									autoComplete="username"
									{...register('username')}
								/>
								<ErrorMessage>{errors.username?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3">
								<Input
									type="password"
									placeholder="Password"
									autoComplete="current-password"
									{...register('password')}
								/>
								<ErrorMessage>{errors.password?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col md={12} className="d-flex my-2">
							<CustomFormCheck
								type="checkbox"
								name="rememberMe"
								id="rememberMe"
								className=""
								{...register('rememberMe')}
							/>
							<Form.Label
								htmlFor="rememberMe"
								className="ps-2 pt-1 align-self-center mb-0 cursor-pointer"
							>
								Remember me
							</Form.Label>
						</Col>
					</Row>
					<div className="text-center">
						<Col md={12}>
							<a href="/dashboard">
								<LoginButton
									className="btn btn-block btn-darken my-3"
									type="submit"
									disabled={isLoading}
								>
									Login {isLoading && <Spinner animation="border" size="sm" />}
								</LoginButton>
							</a>
						</Col>
						<Link to="/forgot" style={{ color: '#00834d' }}>
							<Forgot>Forgot password?</Forgot>
						</Link>
					</div>
				</LoginFormContainer>
			)}
		</div>
	);
}

// function NoticeMessage({ children }) {
// 	return (
// 		<small
// 			className="alert-info mt-1 p-2 d-block"
// 			style={{ borderRadius: '10px' }}
// 		>
// 			{children}
// 		</small>
// 	);
// }

export default LoginForm;
