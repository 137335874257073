import FullPageSpinner from 'components/FullPageSpinner';
import {
	getToken,
	removeToken,
	setRefreshToken,
	setToken,
} from 'helpers/utils/auth-provider';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { auth } from '../api';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

function AuthProvider(props) {
	const navigate = useNavigate();
	const [isBoostraping, setIsBootstraping] = useState(true);
	const [authState, setAuthState] = useState({
		user: null,
		status: 'idle',
	});

	const handleToken = useCallback((cb) => {
		auth
			.getCurrentUser()
			.then((result) => {
				const user = result.data.profile;
				setAuthState((prev) => ({ ...prev, user }));
				setIsBootstraping(false);
				if (cb) cb();
			})
			.catch(() => {
				setIsBootstraping(false);
			});
	}, []);

	useEffect(() => {
		if (getToken()) {
			setIsBootstraping(true);
			handleToken();
		} else {
			setIsBootstraping(false);
		}
	}, []);

	const login = useCallback((form) => {
		setAuthState({ ...authState, status: 'pending' });
		return auth
			.login(form)
			.then((res) => {
				const loginResponse = res.data.login;
				if (
					form.forceLogin === false &&
					loginResponse?.hasOtherDeviceLoggedIn
				) {
					return loginResponse;
				}
				if (loginResponse?.accessToken && loginResponse?.refreshToken) {
					localStorage.removeItem('forceLogoutMessage');
					setToken(loginResponse.accessToken);
					setRefreshToken(loginResponse.refreshToken);
					handleToken(() => {
						navigate('/dashboard', { replace: true });
					});
				} else {
					toast.error('Login failed');
					setAuthState({ ...authState, status: 'rejected' });
				}
				return loginResponse;
			})
			.catch((err) => {
				setAuthState({ user: null, status: 'rejected' });
				toast.error(err?.message || 'Internal Server Error');
			});
	}, []);

	const logout = useCallback(async () => {
		try {
			await auth.logoutUser().then(() => {
				removeToken();
				setAuthState({ user: null, status: 'idle' });
				navigate('/', { replace: true });
			});
		} catch (error) {
			toast.error(error?.message || 'Internal Server Error');
			setAuthState({ user: null, status: 'idle' });
			navigate('/', { replace: true });
		}
	}, [authState, navigate]);

	const value = useMemo(
		() => ({
			user: authState.user,
			login,
			logout,
			handleToken,
			isIdle: authState.status === 'idle',
			isLoading: authState.status === 'pending',
			isError: authState.status === 'rejected',
			isSuccess: authState.status === 'resolved',
		}),
		[authState, handleToken, login, logout],
	);

	if (isBoostraping) {
		return <FullPageSpinner />;
	}
	return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`);
	}
	return context;
}

export { AuthProvider, useAuth };
