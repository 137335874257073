import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
	mutation login($loginInput: LoginInput!) {
		login(loginInput: $loginInput) {
			accessToken
			refreshToken
			hasOtherDeviceLoggedIn
		}
	}
`;

export const LOGOUT_QUERY = gql`
	query logoutUser {
		logoutUser
	}
`;

export const RESET_PASSWORD_MUTATION = gql`
	mutation ResetPassword($username: String!) {
		resetPassword(username: $username)
	}
`;

export const SET_NEW_PASSWORD_MUTATION = gql`
	mutation SetNewPassword($token: String!, $password: String!) {
		setNewPassword(token: $token, password: $password)
	}
`;
