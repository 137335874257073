/* eslint-disable react/no-unstable-nested-components */
import { ReactComponent as NoDataImage } from 'assets/img/noData.svg';
import {
	activeStatus,
	cameraStatus,
	equipmentCarrier,
	posiStopStatus,
	status,
	type,
} from 'components/EquipmentHandler';
import Tables from 'components/Tables';
import React, { useEffect, useState } from 'react';
import { Accordion, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from 'helpers/contexts/AuthContext';
import { useDashboard } from './DashboardContext';
import { TableContainer } from './tables.styled';

function ListView() {
	const { equipments, timezones, filteredResult } = useDashboard();
	const [data, setData] = useState([]);
	const { user } = useAuth();


	useEffect(() => {
		setData(filteredResult || equipments);
	}, [filteredResult, equipments]);

	// Loading state
	if (!equipments)
		return (
			<div className="no-result">
				<div>
					<Spinner animation="border" size="lg" />
				</div>
			</div>
		);

	if (equipments?.length === 0)
		return (
			<div className="no-result">
				<div>No equipments to be shown</div>
			</div>
		);

	if (filteredResult?.length === 0) {
		return (
			<div className="h-85 d-grid align-content-center justify-items-center">
				<NoDataImage />
				<span className="mt-3 fw-bold">No Data Found</span>
			</div>
		);
	}

	const columns = [
		{
			id: 1,
			name: 'EQUIPMENT ID',
			cell: (row) => (
				<td
					className={
						row?.active?.value?.length > 0 && user.access.includes('rig')
							? 'equipment-id'
							: 'equipment-id-deactive'
					}
				>
					{row?.enabled?.value && row.status?.value !== 'Not Activated' && user.access.includes('rig') ? (
						<Link
							to={
								row.type === 'pump'
									? `/pumps/${row.serial}`
									: `/rigs/${row.serial}`
							}
						>
							{row.serial}
						</Link>
					) : (
						row.serial
					)}
				</td>
			),
			selector: (row) => row.serial,
			sortable: true,
			width: '15%',
		},
		{
			name: 'CONNECTION',
			cell: (row) => <>{status(row)}</>,
			selector: (row) => row.status?.value,
			sortable: true,
			width: '15%',
		},
		{
			name: 'TYPE',
			cell: (row) => <>{type(row)}</>,
			selector: (row) => row.type,
			sortable: true,
			width: '8%',
		},
		{
			name: 'CARRIER',
			cell: (row) => equipmentCarrier(row),
			selector: (row) => equipmentCarrier(row),
			sortable: true,
			width: '12%',
		},
		{
			name: 'POSI-STOP',
			cell: (row) => posiStopStatus(row),
			selector: (row) => posiStopStatus(row),
			sortable: true,
			width: '12%',
		},
		{
			name: 'ACTIVITY',
			cell: (row) =>
				activeStatus(row.active.value, row.status.shortmessage) || '-',
			selector: (row) =>
				activeStatus(row.active.value, row.status.shortmessage),
			sortable: true,
			width: '10%',
		},
		{
			name: 'CAMERA',
			cell: (row) => cameraStatus(row),
			selector: (row) => row.camera?.status,
			sortable: true,
			width: '12%',
		},
		{
			name: 'LAST MESSAGE',
			cel: (row) => (row.messages.length === 0 ? '-' : row.messages.slice(10)),
			sortable: false,
			width: '10%',
		},
	];

	return (
		<TableContainer>
			{timezones?.map((zone) => (
				<Accordion defaultActiveKey={zone.name} key={zone.name}>
					<Accordion.Item eventKey={zone.name} key={zone.name} className="mt-3">
						<Accordion.Header>
							{zone.reg[0]} <small>{zone.name}</small>
						</Accordion.Header>
						<Accordion.Body>
							{zone.yards.map((y) => (
								<React.Fragment key={y}>
									<h2>{y}</h2>
									<Tables
										data={data?.filter((item) => item?.yard?.id === y) || []}
										columns={columns}
										progressComponent={
											<Spinner animation="border" variant="primary" size="md" />
										}
										pagination={false}
										defaultSortFieldId={1}
									/>
									<br />
								</React.Fragment>
							))}
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			))}
		</TableContainer>
	);
}

export default ListView;
