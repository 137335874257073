import RigLayout from 'components/Layouts/RigLayout';
import { alerts } from 'helpers/api';
import { AlertsGroup } from 'helpers/const/alerts';
import { Wrapper } from 'pages/alert-management/styled';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Form, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

type SelectedAlertListType = {
	name: string;
	email: boolean;
	sms: boolean;
};
type SelectedAlertType = {
	alertList: [SelectedAlertListType];
	serial: string;
};

const AlertsTab = () => {
	const { id } = useParams();
	const {
		data: listData,
		isLoading: listLoading,
		refetch: refetchAlertsList,
	} = useQuery(`alertsList-${id}`, alerts.getAlertList);
	const { data: userAlerts, refetch: refetchAlerts } = useQuery(
		`userAlerts-${id}`,
		alerts.getUserAlerts,
		{
			enabled: !!id,
			refetchOnWindowFocus: true,
		},
	);

	const [selectedAlerts, setSelectedAlerts] = useState<SelectedAlertType>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userAlerts?.data.userAlerts && userAlerts?.data.userAlerts.length > 0) {
			setSelectedAlerts(
				userAlerts?.data.userAlerts.find((alert) => alert.serial === id),
			);
		}
	}, [userAlerts]);

	const handleSelectAlert = (
		e: React.ChangeEvent<HTMLInputElement>,
		alertName: string,
	) => {
		const isChecked = e.target.checked;
		let oldSlectedAlerts =
			selectedAlerts !== undefined
				? JSON.parse(JSON.stringify(selectedAlerts))
				: null;
		if (oldSlectedAlerts) {
			const foundIndex: number = oldSlectedAlerts?.alertList?.findIndex(
				(alert: SelectedAlertListType) => alert.name === alertName,
			);
			console.log(
				'foundIndex',
				foundIndex,
				alertName,
				oldSlectedAlerts?.alertList?.findIndex(
					(alert: SelectedAlertListType) => alert.name === alertName,
				),
			);
			// If object found in the list
			if (foundIndex >= 0) {
				oldSlectedAlerts.alertList[foundIndex].email = isChecked;
				oldSlectedAlerts.alertList[foundIndex].sms = isChecked;
			} else if (
				oldSlectedAlerts &&
				oldSlectedAlerts.serial !== null &&
				oldSlectedAlerts?.alertList.length > 0
			) {
				// If has alert lists
				oldSlectedAlerts?.alertList.push({
					name: alertName,
					sms: isChecked,
					email: isChecked,
				});
			} else {
				// If it's new object
				oldSlectedAlerts = {
					serial: id,
					alertList: [
						{
							name: alertName,
							sms: isChecked,
							email: isChecked,
						},
					],
				} as SelectedAlertType;
			}
		} else {
			// If it's new object
			oldSlectedAlerts = {
				serial: id,
				alertList: [
					{
						name: alertName,
						sms: isChecked,
						email: isChecked,
					},
				],
			} as SelectedAlertType;
		}
		setSelectedAlerts(oldSlectedAlerts);
	};

	const handleSubmit = async () => {
		try {
			if (selectedAlerts === undefined || selectedAlerts === null) {
				console.log('found nulll', selectedAlerts);
				toast.error('Please select any alert event.');
			} else {
				setLoading(true);
				const request = alerts.updateUserAlerts([selectedAlerts]);
				toast.promise(request, {
					loading: 'Updating alerts...',
					success: 'Alerts updated successfully',
					error: 'Failed to update alerts',
				});
				request
					.then((res) => {
						if (res.data.saveAlert.success) {
							refetchAlerts();
							refetchAlertsList();
						}
					})
					.finally(() => {
						setLoading(false);
					});
			}
		} catch (error: any) {
			toast.error(error.message);
		}
	};

	return (
		<RigLayout>
			<Wrapper>
				<p className="font-medium">
					Get notified via email, SMS and push notifications.
				</p>
				<div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
					{AlertsGroup?.map?.((groupItem) => (
						<Accordion key={groupItem.name} className="">
							<Accordion.Item
								eventKey={groupItem.name}
								key={groupItem.name}
								className="mt-3 "
							>
								<Accordion.Header className="">
									<h3 className="font-bold">
										{groupItem.name}{' '}
									</h3>
								</Accordion.Header>
								<Accordion.Body className="p-0 border-b border-l border-r border-gray-800 rounded-b-lg equipment category">
									{listLoading ? (
										<Spinner />
									) : (
										listData?.data?.alertFunctions?.map?.((listItem: any) =>
											groupItem.tags.find((el) => el === listItem.tags) ? (
												<Accordion key={listItem.name} className="">
													<Accordion.Item
														eventKey={listItem.name}
														key={listItem.name}
														className="py-2"
													>
														<div className="py-1 mx-2.5 duration-300 rounded-lg flex items-center">
															<Form.Check.Input
																id={listItem.name}
																type="checkbox"
																className="ring-1 ring-gray-600 checked:ring-inherit check selectAllEquipment"
																checked={
																	selectedAlerts?.alertList !== undefined
																		? selectedAlerts?.alertList?.find(
																				(alert) => alert.name === listItem.name,
																			)?.email === true
																		: false
																}
																onChange={(e) =>
																	handleSelectAlert(e, listItem.name)
																}
																data-category={listItem.name}
															/>
															<Form.Check.Label
																htmlFor={listItem.name}
																className="mt-1 ml-1 font-medium duration-200 vertical-align-middle hover:font-bold"
																data-name={listItem.name}
																data-desc={listItem.description}
															>
																{listItem.description}
															</Form.Check.Label>
														</div>
													</Accordion.Item>
												</Accordion>
											) : (
												''
											),
										)
									)}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					))}
				</div>
				<div className="flex items-center gap-2 my-2 text-end justify-evenly md:justify-end">
					<Button
						variant="primary-green"
						className="mt-3"
						onClick={handleSubmit}
						disabled={loading}
					>
						Save {loading === true && <Spinner size="sm" animation="border" />}
					</Button>
				</div>
			</Wrapper>
		</RigLayout>
	);
};

export default AlertsTab;
