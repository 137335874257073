import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import SearchInput from 'components/SearchInput';
import TooltipHandler from 'components/TooltipHandler';
import { useDashboard } from 'pages/dashboard/DashboardContext';
import { TableContainer } from 'pages/dashboard/tables.styled';
import React, { useEffect, useState, useTransition } from 'react';
import { Button, Col, Modal, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import useCameraLayout from '../../helpers/hooks/useCameraLayout';
import {
	DataContainer,
	FilterContainer,
	HeaderWrapper,
	SelectionModal,
} from './styles/EquipmentsModal.styled';

export default function EquipmentsModal({
	editingItem,
	show,
	setModalShow,
	activeEqs,
	setIsFetchinOnUpdate,
	refetch,
}: {
	editingItem: any;
	show: boolean;
	setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	activeEqs: any[];
	setIsFetchinOnUpdate: React.Dispatch<React.SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const { equipments, isLoading, timezones }: any = useDashboard();
	const [eqs, setEqs] = useState<string[]>(editingItem?.selection || []);
	const [filtered, setFiltered] = useState([]);
	const [stopEquipmentRefetch, setStopEquipmentRefetch] = useState(false);
	const [, setSearch] = useState('');
	const [uniqueYards, setUniqueYards] = useState<string[]>([]);
	const [selectedRegion, setSelectedRegion] = useState('');
	const [layoutName, setLayoutName] = useState(editingItem?.view_name || '');
	const { createCameraLayout, updateCameraLayout } = useCameraLayout();
	const [, startTransition] = useTransition();

	useEffect(() => {
		if (show) {
			startTransition(() => {
				setEqs(editingItem?.selection || []);
				setLayoutName(editingItem?.view_name || '');
			});
		}
	}, [show]);

	const onModalClose = () => {
		startTransition(() => {
			setLayoutName('');
			setEqs([]);
			setModalShow(false);
		});
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearch(value);
		if (value === '') {
			setFiltered(equipments);
		} else {
			const filteredItems = equipments.filter(
				(item: any) =>
					item?.equip_data?.serial
						?.toLowerCase()
						.includes(value.toLowerCase()) ||
					item?.equip_data?.region?.name
						?.toLowerCase()
						.includes(value.toLowerCase()) ||
					item?.equip_data?.region?.id
						?.toLowerCase()
						.includes(value.toLowerCase()),
			);
			setFiltered(filteredItems);
		}
	};
	const handleTimezone = (id: string) => () => {
		setSearch('');
		if (id === '') {
			setFiltered(equipments);
			setSelectedRegion('All');
		} else {
			const filteredItems = equipments.filter(
				(item: {
					equip_data: { region: { id: string }; serial: string | string[] };
				}) => item?.equip_data?.region?.id?.toLowerCase() === id.toLowerCase(),
			);
			setFiltered(filteredItems);
			setSelectedRegion(id);
		}
	};

	const toggleSelection = (item: string) => {
		if (eqs.includes(item)) {
			setEqs((prev) => prev.filter((eq) => eq !== item));
		} else {
			setEqs((prev) => [...prev, item]);
		}
	};

	const handleCheckbox = (
		e: React.ChangeEvent<HTMLInputElement>,
		subItems: any = null,
	) => {
		let ids: any = [];
		if (subItems && Object.keys(subItems).length > 0) {
			ids = Object.keys(subItems);
		}
		if (ids && ids.length > 0) {
			if (e.target.checked) {
				setEqs((prev) => [...prev, ...Object.keys(subItems)]);
			} else {
				setEqs((prev) => prev.filter((eq) => !ids.includes(eq)));
			}
		} else {
			toggleSelection(e.target.id.toUpperCase());
		}
	};

	/**
	 * Checks if all the equipment IDs in the provided `subItems` object are included in the `eqs` array.
	 *
	 * @param {Object} subItems - An object where the keys are equipment IDs.
	 * @returns {boolean} - `true` if all the equipment IDs in `subItems` are included in `eqs`, `false` otherwise.
	 */
	const hasCheckAll = (subItems) =>
		subItems &&
		Object.keys(subItems).length > 0 &&
		Object.keys(subItems).every((eq) => eqs?.includes(eq));

	/**
	 * Checks if the provided `subItems` object has an indeterminate checkbox state.
	 *
	 * An indeterminate checkbox state occurs when some, but not all, of the checkboxes
	 * in a group are selected.
	 *
	 * @param {Object} [subItems=null] - An object where the keys are equipment IDs.
	 * @returns {boolean} - `true` if the checkboxes are in an indeterminate state, `false` otherwise.
	 */
	const checkIndeterminate = (subItems = null) => {
		if (subItems === null || (eqs && eqs.length === 0)) return false;
		const ids: string[] = Object.keys(subItems);
		return (
			ids &&
			eqs &&
			ids.length > 1 &&
			ids.some((id) => eqs?.includes(id)) &&
			!ids.every((id) => eqs?.includes(id))
		);
	};

	const handleSubmit = async () => {
		if (layoutName === '') {
			toast.error('Please enter a name for the layout');
			return;
		}
		if (eqs.length === 0) {
			toast.error('Please select at least one equipment camera');
			return;
		}

		try {
			if (editingItem) {
				updateCameraLayout({
					id: parseInt(editingItem.id, 10),
					selection: eqs,
					viewName: layoutName,
				});
			} else {
				createCameraLayout({
					selection: eqs,
					viewName: layoutName,
				});
			}
			setIsFetchinOnUpdate(true);
			await refetch();
			setIsFetchinOnUpdate(false);
			onModalClose();
		} catch (error) {
			setModalShow(true);
		}
	};

	useEffect(() => {
		if (equipments && !stopEquipmentRefetch) {
			if (activeEqs && activeEqs.length > 0) {
				setEqs(activeEqs);
			}
			setFiltered(equipments);
			setStopEquipmentRefetch(true);
		}
	}, [activeEqs, equipments]);
	useEffect(() => {
		const unique2 = [
			...new Set(
				filtered?.map(
					(item: { equip_data: { region: { yard: { name: any } } } }) =>
						item?.equip_data.region?.yard?.name,
				),
			),
		];
		setUniqueYards(unique2);
	}, [filtered]);

	if (isLoading) {
		return (
			<Modal
				size="lg"
				centered
				show={show}
				onHide={() => {
					onModalClose();
				}}
				className="profile-modal"
			>
				<Modal.Body>
					<Spinner
						animation="border"
						size="sm"
						className="mx-auto"
						style={{ display: 'block' }}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	const hasNoSubItems = (subItems: any) =>
		subItems === null || subItems?.length === 0;

	return (
		<SelectionModal
			size="xl"
			centered
			show={show}
			onHide={() => {
				onModalClose();
			}}
			className="profile-modal"
		>
			<Modal.Body>
				<TableContainer className="mt-0">
					<HeaderWrapper className="d-flex">
						<div className="back-container flex items-center">
							<Right />
							<button
								type="button"
								onClick={() => {
									onModalClose();
								}}
								style={{ all: 'unset', cursor: 'pointer' }}
							>
								<span className="ms-2"> Back </span>
							</button>
						</div>
						<div className="d-flex gap-2 justify-content-between align-items-center">
							<TooltipHandler
								content="Search based on Equipment Serial, Region Name, or Region ID"
								placement="bottom"
							>
								<HelpIcon />
							</TooltipHandler>
							<SearchInput
								placeholder="Search Equipment"
								onChange={handleSearch}
							/>
						</div>
					</HeaderWrapper>

					<input
						type="text"
						id="view_name"
						placeholder="Layout Name"
						defaultValue={editingItem?.view_name || ''}
						onChange={(e) => setLayoutName(e.target.value)}
						className="mt-4"
					/>
					<h1 className="mb-4 assignment-header "> Cameras </h1>

					<div className="d-flex gap-4">
						<FilterContainer>
							<button
								type="button"
								className="all-button"
								key={Math.random()}
								onClick={handleTimezone('')}
							>
								<span>All </span>
							</button>
							{timezones?.map((zone: { name: string; id: string }) => (
								<button
									key={zone.name}
									type="button"
									className="timezone-button"
									style={{
										paddingBottom:
											zone.name === timezones[timezones.length - 1].name
												? '0.5rem'
												: '0.25rem',
										borderBottom:
											zone.name === timezones[timezones.length - 1].name
												? 'none'
												: '1px solid #D3D3D3',
										borderLeft:
											zone.name === selectedRegion
												? '3px solid #00834d'
												: 'none',
									}}
									onClick={handleTimezone(zone.name)}
								>
									<span>{zone.name}</span>
								</button>
							))}
						</FilterContainer>

						<DataContainer>
							{uniqueYards?.map((yard) => (
								<div className="mb-2 zone-container" key={`${yard}_`}>
									<div className="zone-title">{yard}</div>
									<div className="grid grid-flow-row grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-5 gap-2 duration-500 shrink m-1">
										{filtered
											?.filter(
												(item: {
													equip_data: { region: { yard: { name: any } } };
												}) =>
													item?.equip_data?.region?.yard?.name?.toLowerCase() ===
													yard?.toLowerCase(),
											)
											?.map(
												(eqItem: {
													equip_data: {
														serial: string;
														camera: { data: any };
														region: {
															yard: { id: string; name: string };
															name: string;
															id: string;
														};
														yard: { id: string; name: string };
													};
												}) => (
													<div
														key={`${yard}_${eqItem?.equip_data?.serial}_`}
														className={`border rounded-lg py-2 pl-4 pr-2  duration-200 ${
															hasNoSubItems(eqItem?.equip_data?.camera?.data)
																? ''
																: 'hover:shadow-md'
														}`}
													>
														<FormControlLabel
															label={
																<Typography
																	style={{
																		fontSize: '14px',
																		fontWeight: '800',
																		color: hasNoSubItems(
																			eqItem?.equip_data?.camera?.data,
																		)
																			? 'gray'
																			: 'black',
																		paddingLeft: '4px',
																	}}
																>
																	{eqItem?.equip_data?.serial}
																</Typography>
															}
															control={
																<Checkbox
																	size="large"
																	sx={{
																		padding: 0,
																		margin: 0,
																		color: 'black',
																	}}
																	title="title"
																	disableRipple
																	color="success"
																	name={eqItem?.equip_data?.yard?.id}
																	id={eqItem?.equip_data?.serial?.toLowerCase()}
																	disabled={hasNoSubItems(
																		eqItem?.equip_data?.camera?.data,
																	)}
																	indeterminate={checkIndeterminate(
																		eqItem?.equip_data?.camera?.data,
																	)}
																	checked={hasCheckAll(
																		eqItem?.equip_data?.camera?.data,
																	)}
																	onChange={(e) =>
																		handleCheckbox(
																			e,
																			eqItem?.equip_data?.camera?.data,
																		)
																	}
																/>
															}
														/>

														<div
															style={{
																justifyContent: 'space-between',
															}}
															className="mt-2"
														>
															{eqItem?.equip_data?.camera?.data &&
																Object.keys(
																	eqItem.equip_data?.camera?.data,
																)?.map((key) => (
																	<Col
																		lg={3}
																		key={`${key}_`}
																		className="assignment-item"
																	>
																		<FormControlLabel
																			label={
																				<Typography
																					style={{
																						fontSize: '14px',
																						fontWeight: '600',
																						paddingLeft: '4px',
																					}}
																				>
																					{key.replace(
																						`${eqItem?.equip_data?.serial}`,
																						'Camera',
																					)}
																				</Typography>
																			}
																			className="font-extralight text-nowrap equipment-id duration-300"
																			control={
																				<Checkbox
																					size="large"
																					sx={{
																						padding: 0,
																						margin: 0,
																						color: 'black',
																					}}
																					disableRipple
																					color="success"
																					id={key?.toLowerCase()}
																					name={eqItem?.equip_data?.yard?.id}
																					checked={eqs?.includes(key)}
																					value={key}
																					onChange={(e) => handleCheckbox(e)}
																				/>
																			}
																		/>
																	</Col>
																))}
														</div>
													</div>
												),
											)}
									</div>
								</div>
							))}

							<Button
								variant="primary-green"
								className="mt-4 float-end"
								onClick={handleSubmit}
							>
								Save
							</Button>
						</DataContainer>
					</div>
				</TableContainer>
			</Modal.Body>
		</SelectionModal>
	);
}
