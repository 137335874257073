import { Col } from 'react-bootstrap';
import { FaEye, FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { PosiCardHeader, PosiCardWrapper } from './styles/PosiCard.styled';

export default function LayoutCard({
	item,
	index,
	handleEditModal,
	handleDeleteModal,
}: {
	item: any;
	index: number;
	handleEditModal: (data: any) => void;
	handleDeleteModal: (data: any) => void;
}) {
	const navigate = useNavigate();
	return (
		<Col lg={6} style={{ padding: '0.25rem' }} index={index}>
			<PosiCardWrapper className="border rounded-md hover:shadow-md duration-300">
				<PosiCardHeader>
					<Link
						color="primary"
						to={`/camera-feed/${item.id}`}
						rel="noopener noreferrer"
					>
						<span className="text-[#00834d] font-semibold duration-500">
							{item?.view_name}
						</span>
					</Link>
					<div className="d-flex gap-3 flex items-center">
						<Button
							type="button"
							className="p-0 duration-300"
							onClick={() => {
								handleEditModal(item);
							}}
						>
							<FaRegEdit />
						</Button>
						<Button
							type="button"
							variant="delete-secondary"
							className="p-0 duration-300"
							onClick={() => {
								handleDeleteModal(item);
							}}
						>
							<FaRegTrashAlt />
						</Button>
						<Button
							type="button"
							className="p-0 duration-300"
							onClick={() => {
								navigate(`/camera-feed/${item.id}`);
							}}
						>
							<FaEye />
						</Button>
					</div>
				</PosiCardHeader>

				<div className="d-flex gap-2 mt-2">
					{/* Show list of selection ids */}
					{item.selection.length ? (
						<div className="messages-container border-t w-full p-2">
							<span className="title">Serial IDs</span>
							<div className="messages-list">
								<p className="font-bold text-sm">{item.selection.join(', ')}</p>
							</div>
						</div>
					) : (
						<p>No serial ids added. Click on edit to add serial ids</p>
					)}
				</div>
			</PosiCardWrapper>
		</Col>
	);
}
