export const AlertsGroup = [
	// { name: 'Engine', description: '', tags: ['eng'] },
	{ name: 'Draw Works', description: '', tags: ['dw'] },
	{ name: 'Camera Service', description: '', tags: ['cs'] },
	// { name: 'Swivel', description: '', tags: ['sw'] },
	// {
	// 	name: 'BOP/Rod Handler/Interlock',
	// 	description: '',
	// 	tags: ['bop', 'rod'],
	// },
	// { name: 'Weather', description: '', tags: ['wth'] },
	// { name: 'Miscellaneous', description: '', tags: ['misc'] },
];
