import LogoIcon from 'assets/img/logo.png';
import styled from 'styled-components';
import CameraDetail, { getStatus } from '../details/rig/camera/CameraDetail';
import { Info, Wrapper } from './styles/CameraDetails.styled';

interface PosiInfoProps {
	levelStyle?: string;
}

const PosiInfo = styled.div<PosiInfoProps>`
	// position: absolute;
	// margin-left: 1em;
	// z-index:1;
	// bottom: 6.5em;
	height: 88%;
	> svg {
		height: 70%;
	}
	.level {
		transition: transform 1s ease-out;
		transform: 'translateY(0px)';
		${(props) => (props.levelStyle ? props.levelStyle : '')}
	}
	> span {
		display: block;
		text-shadow:
			-1px 0 black,
			0 1px black,
			1px 0 black,
			0 -1px black;
		color: white;
		font-size: small;
	}
	.posi-status {
		width: 1rem;
	}
	.bar {
		width: 1rem;
	}
`;
export default function CameraDetails({
	data,
	handleFullView,
}: {
	data: any;
	handleFullView?: (values: any) => () => void;
}) {
	let level = 192;
	//  If the `flag` is `1` then move camera vertical bar. As per old codebase.
	// Commented for verification.
	// if (getStatus(data)) {
	level = data?.positionPercent * 2 || 0;
	if (level > 192) level = 192;
	if (level < 8) level = 0;
	level *= -1;
	// } // End IF
	const levelStyle = `
			fill: rgb(180, 33, 35); 
			fill-opacity: 1; stroke: rgb(0, 0, 0); 
			stroke-width: 0; 
			stroke-dasharray: none; 
			stroke-opacity: 1;
			transform: translateY(${level}px)
		`;
	const status = getStatus(data.data[0]);
	return (
		<Wrapper
			draggable
			id={data?.equipment}
			onDragStart={(e) => {
				e.dataTransfer.setData('eq', data?.equipment);
				e.dataTransfer.effectAllowed = 'move';
			}}
			onDragEnd={(e) => {
				e.dataTransfer.clearData();
			}}
		>
			<button
				type="button"
				className="camera-wrapper"
				aria-label="Click to view camera feed"
				// onClick={handleFullView && handleFullView(data)}
			>
				<Info onClick={handleFullView && handleFullView(data)}>
					<PosiInfo>
						<CameraDetail
							item={[
								data?.equipment,
								{
									cameraData: [{ status: data?.camera }],
								},
							]}
							status={status}
							id={data.equipment}
							data={data.data[0]}
						/>
					</PosiInfo>
				</Info>
				<img
					src={
						data.data[0].status.camera?.data?.[data.equipment]?.[0]?.path ||
						data?.camera?.data?.[0]?.path ||
						LogoIcon
					}
					alt=""
					className="camera-img"
					style={{
						// objectFit: data?.camera?.data?.[0]?.path ? 'cover' : 'none',
						background: !data?.camera?.data?.[0]?.path ? 'black' : 'white',
					}}
				/>
			</button>
		</Wrapper>
	);
}
CameraDetails.defaultProps = {
	handleFullView: null,
};
