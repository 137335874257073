/**
 * Converts a temperature value from Celsius to Fahrenheit.
 *
 * @param celsius - The temperature value in Celsius.
 * @returns The temperature value in Fahrenheit.
 */
export const celsiusToFahrenheit = (celsius: number): string =>
	((celsius * 9) / 5 + 32).toFixed(1);

/**
 * Converts a temperature value from Fahrenheit to Celsius.
 *
 * @param fahrenheit - The temperature value in Fahrenheit.
 * @returns The temperature value in Celsius.
 */
export const fahrenheitToCelsius = (fahrenheit: number): string =>
	(((fahrenheit - 32) * 5) / 9).toFixed(1);

/**
 * Converts a speed in kilometers per hour (kmh) to miles per hour (mph).
 *
 * @param kmh - The speed in kilometers per hour.
 * @returns The speed in miles per hour.
 */
export const kmhToMph = (kmh: number): number => kmh * 0.621371;

/**
 * Converts a speed in miles per hour (mph) to kilometers per hour (kmh).
 *
 * @param mph - The speed in miles per hour.
 * @returns The speed in kilometers per hour.
 */
export const mphToKmh = (mph: number): number => mph * 1.60934;
