import { EQUIPMENT_REPORT_GENERATE_PDF } from 'helpers/queries/equipmentQuery';
import { getToken } from 'helpers/utils/auth-provider';
import { getUnixTimestamp } from 'helpers/utils/formatters';
import client from './apolloClient';

export const generateReport = async (data) => {
	const token = getToken();
	const serial = data.equipment;
	const { date } = data;
	const timeStart = data.timeStarts !== '' ? data.timeStarts : '00:00:00';
	const timeEnd = data.timeEnds !== '' ? data.timeEnds : '23:59:59';
	const reportType = data.reportType !== '' ? data.reportType : 'day';
	const dateTimeStart = `${date}T${timeStart}`;
	const dateTimeEnd = `${date}T${timeEnd}`;

	const range = `${getUnixTimestamp(dateTimeStart) / 1000},${
		getUnixTimestamp(dateTimeEnd) / 1000
	}`;
	return client.query({
		query: EQUIPMENT_REPORT_GENERATE_PDF,
		fetchPolicy: 'network-only',
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		variables: {
			params: {
				serial,
				range,
				date,
				timeStart,
				timeEnd,
				reportType,
			},
		},
	});
};
