import { GET_DRAWWORKS_QUERY } from 'helpers/queries/drawerQuery';
import { getToken } from 'helpers/utils/auth-provider';
import moment from 'moment';
import {
	CAMERA_DATA_QUERY,
	CREATE_NEW_EQUIPMENT_MUTATION,
	DELETE_EQUIPMENT_QUERY,
	EQUIPMENTS_QUERY,
	EQUIPMENTS_STATUS_QUERY,
	EQUIPMENT_CHART_DATA_QUERY,
	EQUIPMENT_DATA_QUERY,
	EQUIPMENT_DETAILS_BY_SERIAL,
	UPDATE_EQUIPMENT_MUTATION,
} from '../queries/equipmentQuery';
import client from './apolloClient';

const cameraUrlBase = 'https://camera.rigseye.com/';

// const scriptsURLEndpoint = scriptsURL();

export const getEquipments = () =>
	client.query({
		query: EQUIPMENTS_STATUS_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

// Get all equipment assigned to a user
export const getEquipmentsByUser = () =>
	client.query({
		query: EQUIPMENTS_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

// Call equipment status individual
export const getStatusByEquipment = (id) =>
	client.get(`/equipments/${id}/status`).then((res) => res.data);

export const getEquipmentChartActivity = (id, starttime, endtime, equipment) =>
	client.query({
		query: EQUIPMENT_CHART_DATA_QUERY,
		variables: {
			serial: id,
			graphs:
				equipment ||
				'weight|position|drawworks|pump|swivelrpm|swiveltorque|swivelpressure|suction|well',
			range: [starttime, endtime],
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

export const getEquipmentChart = (id, starttime, endtime, charts) =>
	client.query({
		query: EQUIPMENT_CHART_DATA_QUERY,
		variables: {
			serial: id,
			graphs: charts,
			range: [starttime, endtime],
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

export const getEquipmentChartByAmount = (id, charts) =>
	client.query({
		query: EQUIPMENT_CHART_DATA_QUERY,
		variables: {
			serial: id,
			graphs: charts,
			amount: 1,
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

export const getEquipmentChartDate = (id, date, equipment) =>
	client.query({
		query: EQUIPMENT_CHART_DATA_QUERY,
		variables: {
			serial: id,
			graphs:
				equipment ||
				'weight|position|drawworks|pump|swivelrpm|swiveltorque|swivelpressure|suction|well',
			range: [moment(date).format('YYYY-MM-DD')],
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

export const getCameraFeed = (id) =>
	client.query({
		query: CAMERA_DATA_QUERY,
		variables: {
			serial: id,
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

export const getEquipmentDetails = async (id) =>
	client.query({
		query: EQUIPMENT_DATA_QUERY,
		variables: {
			serial: id,
			amount: 1,
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

export const getEquipmentActivity = async (id, byHour) => {
	const variables = {
		serial: id,
		isToday: true,
	};
	if (byHour) {
		variables.by = 'hour';
	}
	const { data } = await client.query({
		query: GET_DRAWWORKS_QUERY,
		variables,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});
	if (data && data.getDrawworks) {
		return data.getDrawworks;
	}
	return { drawdata: null };
};

export const addEquipmentProfile = async (values) => {
	const result = await client.mutate({
		mutation: CREATE_NEW_EQUIPMENT_MUTATION,
		variables: { equipmentArgs: values },
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

	if (
		result.data &&
		result.data.createEquipment &&
		result.data.createEquipment.success
	) {
		return result.data.createEquipment;
	}
	if (result.errors && result.errors.length) {
		throw result.errors[0].message;
	} else {
		throw result.data.createEquipment.message;
	}
};

export const getEquipmentProfile = async (serial) => {
	const result = await client.query({
		query: EQUIPMENT_DETAILS_BY_SERIAL,
		variables: { serial },
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});
	return result.data.equipment;
};

export const updateEquipmentProfile = async (values) => {
	const result = await client.mutate({
		mutation: UPDATE_EQUIPMENT_MUTATION,
		variables: { serial: values.serial, equipmentArgs: values },
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

	if (
		result.data &&
		result.data.updateEquipment &&
		result.data.updateEquipment.success
	) {
		return result.data.updateEquipment;
	}
	if (result.errors && result.errors.length) {
		throw result.errors[0].message;
	} else {
		throw result.data.updateEquipment.message;
	}
};

export const deleteEquipment = async (serial) => {
	const result = await client.query({
		query: DELETE_EQUIPMENT_QUERY,
		variables: { serial },
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});
	if (result?.data?.deleteEquipment?.success)
		return result.data.deleteEquipment;
	throw result.errors[0].message;
};
