import { RigDetailContextProvider } from 'helpers/contexts/RigDetailProvider';
import AlertsTab from './AlertsTab';

const Alerts = () => (
	<RigDetailContextProvider>
		<AlertsTab />
	</RigDetailContextProvider>
);

export default Alerts;
