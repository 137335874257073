import { getToken } from 'helpers/utils/auth-provider';
import {
	ALERT_FUNCTION_LIST_QUERY,
	SAVE_USER_ALERT_MUTATION,
	USER_ALERTS_QUERY,
} from '../queries/alertFunctionQuery';
import { NOTIFICATION_QUERY } from '../queries/notificationQuery';
import client from './apolloClient';

export const getActiveAlerts = () => {
	// Before load the data, Clear the local storage of alerts cache data.
	if (window.location.pathname !== '/alerts') {
		localStorage.removeItem('resetAlerts');
		localStorage.removeItem('userAlerts');
	}
	client.query({
		query: NOTIFICATION_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});
};
export default {
	getUserAlerts: () =>
		client.query({
			query: USER_ALERTS_QUERY,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			fetchPolicy: 'network-only',
		}),
	getAlertList: () =>
		client.query({
			query: ALERT_FUNCTION_LIST_QUERY,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			fetchPolicy: 'network-only',
		}),
	updateUserAlerts: async (alerts) =>
		client.mutate({
			mutation: SAVE_USER_ALERT_MUTATION,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			variables: { alerts },
		}),
};
