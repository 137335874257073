import styled from 'styled-components';

const Wrapper = styled.div`
	height: 100%;
	.status-light {
		position: absolute;
		top: 1.75rem;
		left: 2rem;
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 50%;
		cursor: help;
	}
	.camera-wrapper {
		all: unset;
		height: 100%;
		width: 100%;
		display: grid;
		cursor: pointer;
	}
	.camera-img {
		width: 100%;
		height: 100%;
	}
`;
const Info = styled.div`
	position: absolute;
	bottom: 2.5rem;
	left: 0rem;
	color: white;
	text-shadow:
		-1px 0 black,
		0 1px black,
		1px 0 black,
		0 -1px black;
	width: 94%;
	padding: 0.2em;
	font-size: small;
	height: 86%;
	.speed {
		position: absolute;
		right: 0em;
		bottom: 2em;
		display: none;
	}
	.blurry-text {
		color: transparent;
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	}
`;

const Camstatus = styled.span`
	span {
		margin-right: 0.5em;
	}
	.online svg {
		fill: #00854b;
	}

	.offline svg {
		fill: #ff0000;
	}
`;

export { Wrapper, Info, Camstatus };
