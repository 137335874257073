import { ReactComponent as Calendar404 } from 'assets/img/404calendar.svg';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import moment from 'moment';
import DetailsCard from 'pages/details/DetailsCard';
import styled from 'styled-components';

const EventsWrapper = styled(DetailsCard)`
	.no-message-text {
		color: ${({ theme }) => theme.colors.gray3};
		font-size: 0.857rem;
	}
	.date {
		font-size: 0.857rem;
	}
	.data {
		font-size: 0.857rem;
	}
`;

export default function Events() {
	const { data }: any = useRigDetail();

	return data?.status?.eventsnew?.length === 0 ? (
		<EventsWrapper
			className="mb-0 cards cards-full-height flex-grow-1"
			title="Today's Rig Events"
			print
			noalign
		>
			<div className="d-grid">
				<Calendar404 className="event-404" />
				<span className="mt-3 text-center no-message-text">No Events</span>
			</div>
		</EventsWrapper>
	) : (
		<EventsWrapper
			className="cards cards-full-height flex-grow-1"
			title="Today's Rig Events"
			print
			noalign
			style={{ overflowY: 'scroll' }}
			fullHeight
		>
			{data?.status?.eventsnew
				?.filter(
					(msg, index) =>
						data?.status?.eventsnew[index - 1]?.description !==
							msg.description &&
						!data?.status?.eventsnew[index - 1]?.description.includes(
							msg.description,
						),
				)
				?.map((msg) => (
					<div
						key={msg.time}
						style={{ display: 'flex', borderBottom: '1px solid #e0e0e0' }}
						className="w-full"
					>
						<div
							style={{
								// width: '30%',
								padding: '12px 8px',
								textAlign: 'left',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
							className="w-[40%] text-left date"
						>
							{moment(msg.date * 1)
								?.tz(data?.timezoneIdentifier || 'America/Chicago')
								?.format('hh:mm A')}
						</div>
						{/* <div
						style={{
							// width: '40%',
							padding: '12px 8px',
							textAlign: 'left',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
						className="w-[40%] xl:w-[25%] text-left description"
					>
						{msg.description}
					</div> */}
						<div className="w-full px-2 py-2 overflow-hidden text-left xl:text-right data text-ellipsis whitespace-nowrap">
							{/* {msg.description}
							<br /> */}
							{msg.event}
						</div>
						{/* <div
						style={{
							// width: '30%',
							padding: '12px 8px',
							// textAlign: 'left',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
						className="data"
					>
						 {msg.message}
					</div> */}
					</div>
				))}
		</EventsWrapper>
	);
}
