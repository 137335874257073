import DetailsLayout from 'components/Layouts/DashboardLayout';
import { useAuth } from 'helpers/contexts/AuthContext';
import DetailsInfo from 'pages/details/rig/Header';
import { DetailsContainer } from 'pages/details/styled';
import { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(DetailsContainer)`
	.back-button {
		padding-bottom: 0.75rem;
		margin-right: 1rem;
	}
	.back-link {
		margin-right: 0.75rem;
		color: ${({ theme }) => theme.colors.black};
		text-decoration: none;
	}
	.nav-view {
		margin-bottom: 1.3rem !important;
		margin-top: 1.3rem;
		flex: 1;

		a {
			margin-right: 1.5rem;
		}
	}
	.cards-full-height {
		min-height: 300px;
		/* height: auto; */
		height: 220px;
		margin-bottom: 8px;
	}
`;
function Details({ children }) {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation().pathname;
	const { user } = useAuth();

	useEffect(() => {
		// Check the user's access and redirect them to the appropriate page
		if (location.includes(`rigs/${id}`) && !user.access.includes('rig')) {
			navigate(`/404`, { replace: true });
		} else if (
			location.includes(`rigs/${id}/charts`) &&
			!user.access.includes('drilling')
		) {
			navigate(`/rigs/${id}`, { replace: true });
		} else if (
			location.includes(`rigs/${id}/engine`) &&
			!user.access.includes('can')
		) {
			navigate(`/rigs/${id}`, { replace: true });
		} else if (
			location.includes(`rigs/${id}/camera`) &&
			!user.access.includes('cam')
		) {
			navigate(`/rigs/${id}`, { replace: true });
		} else if (
			location.includes(`rigs/${id}/docsys`) &&
			!user.access.includes('docsys')
		) {
			navigate(`/rigs/${id}`, { replace: true });
		}
	}, [location, user]);

	return (
		<DetailsLayout>
			<Wrapper>
				<DetailsInfo />
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center back-button">
						<Link to="/dashboard" className="flex items-center back-link ">
							<FaArrowLeft />
							<span className="ms-2">BACK</span>
						</Link>
					</div>
					<Nav className="nav-view">
						<Nav.Item
							style={{ display: user.access.includes('rig') ? '' : 'none' }}
						>
							<Link
								to={`/rigs/${id}`}
								style={{
									pointerEvents: user.access.includes('rig') ? 'auto' : 'none',
								}}
								className={`${location === `/rigs/${id}` ? 'active' : ''} ${user.access.includes('rig') ? 'cursor-pointer ' : 'cursor-not-allowed'} `}
							>
								DETAILS
							</Link>
						</Nav.Item>
						<Nav.Item
							style={{
								display: user.access.includes('drilling') ? '' : 'none',
							}}
						>
							<Link
								to={`/rigs/${id}/charts`}
								style={{
									pointerEvents: user.access.includes('drilling')
										? 'auto'
										: 'none',
								}}
								className={`${location === `/rigs/${id}/charts` ? 'active' : ''} ${user.access.includes('drilling') ? 'cursor-pointer' : 'cursor-not-allowed'} `}
							>
								ACTIVITY
							</Link>
						</Nav.Item>
						<Nav.Item
							style={{ display: user.access.includes('can') ? '' : 'none' }}
						>
							<Link
								style={{
									pointerEvents: user.access.includes('can') ? 'auto' : 'none',
								}}
								to={`/rigs/${id}/engine`}
								className={`${location === `/rigs/${id}/engine` ? 'active' : ''} ${user.access.includes('can') ? 'cursor-pointer' : 'cursor-not-allowed'} `}
							>
								ENGINE
							</Link>
						</Nav.Item>
						<Nav.Item
							style={{ display: user.access.includes('cam') ? '' : 'none' }}
						>
							<Link
								style={{
									pointerEvents: user.access.includes('cam') ? 'auto' : 'none',
								}}
								to={`/rigs/${id}/camera`}
								className={`${location === `/rigs/${id}/camera` ? 'active' : ''} ${user.access.includes('cam') ? 'cursor-pointer' : 'cursor-not-allowed'} `}
							>
								CAMERA VIEW
							</Link>
						</Nav.Item>
						<Nav.Item
							style={{ display: user.access.includes('docsys') ? '' : 'none' }}
						>
							<Link
								style={{
									pointerEvents: user.access.includes('docsys')
										? 'auto'
										: 'none',
								}}
								to={`/rigs/${id}/docsys`}
								className={`${location === `/rigs/${id}/docsys` ? 'active' : ''} ${user.access.includes('docsys') ? 'cursor-pointer' : 'cursor-not-allowed'} `}
							>
								DOCSYS
							</Link>
						</Nav.Item>
						<Nav.Item
						// style={{ display: user.access.includes('docsys') ? '' : 'none' }}
						>
							<Link
								// style={{
								// 	pointerEvents: user.access.includes('docsys')
								// 		? 'auto'
								// 		: 'none',
								// }}
								to={`/rigs/${id}/alerts`}
								className={`${location === `/rigs/${id}/alerts` ? 'active' : ''} ${user.access.includes('docsys') ? 'cursor-pointer' : 'cursor-not-allowed'} `}
							>
								ALERTS
							</Link>
						</Nav.Item>
					</Nav>
				</div>
				{children}
			</Wrapper>
		</DetailsLayout>
	);
}

export default Details;
