import { Celsius, Fahrenheit } from 'components/symbols/';
import {
	celsiusToFahrenheit,
	fahrenheitToCelsius,
} from 'helpers/utils/unit-customization';
import React, { useEffect, useState } from 'react';

/**
 * Converts a temperature unit to its corresponding string or React component.
 *
 * @param {string} userUnit - The temperature unit to be converted ('celsius' or 'fahrenheit').
 * @param {boolean} [isString=false] - If true, returns the unit as a string (e.g. '°C', '°F'); otherwise, returns a React component.
 * @returns {string|React.ReactNode} - The converted temperature unit.
 */
export const convertTemperatureUnit = (
	userUnit,
	isString = false,
): string | React.ReactNode => {
	if (isString) return userUnit === 'celsius' ? '°C' : '°F';
	return userUnit === 'celsius' ? <Celsius /> : <Fahrenheit />;
};

/**
 * Converts a temperature value from one unit to another.
 *
 * @param {string} rigTemp - The temperature value to be converted.
 * @param {string} rigUnit - The unit of the input temperature value ('F' for Fahrenheit, 'C' for Celsius).
 * @param {string} userUnit - The desired unit for the output temperature ('fahrenheit' for Fahrenheit, 'celsius' for Celsius).
 * @returns {number} - The converted temperature value.
 */
export const convertTemperatureValue = (
	rigTemp: string,
	rigUnit: string,
	userUnit: string,
) => {
	let Temperature: any = '-';
	// Convert Fahrenheit to Celsius
	if (userUnit === 'celsius' && rigUnit === 'F') {
		Temperature =
			rigTemp !== '-' ? fahrenheitToCelsius(parseFloat(rigTemp)) : '-';
	}
	// No Conversion needed for Celsius to Celsius
	if (userUnit === 'celsius' && rigUnit === 'C') {
		Temperature = rigTemp !== '-' ? parseFloat(rigTemp) : '-';
	}
	// Convert Celsius to Fahrenheit
	if (userUnit === 'fahrenheit' && rigUnit === 'C') {
		Temperature =
			rigTemp !== '-' ? celsiusToFahrenheit(parseFloat(rigTemp)) : '-';
	}
	// No Conversion needed for Fahrenheit to Fahrenheit
	if (userUnit === 'fahrenheit' && rigUnit === 'F') {
		Temperature = rigTemp !== '-' ? parseFloat(rigTemp).toFixed(1) : '-';
	}
	return Temperature;
};

/**
 * Converts a temperature value from one unit to another.
 *
 * @param {number} rigTemp - The temperature value to be converted.
 * @param {string} rigUnit - The unit of the input temperature value ('F' for Fahrenheit, 'C' for Celsius).
 * @param {string} userUnit - The desired unit for the output temperature ('fahrenheit' for Fahrenheit, 'celsius' for Celsius).
 * @returns {React.ReactNode} - The converted temperature value with the appropriate unit.
 */
const RigTemperatureConverter = ({ rigTemp, rigUnit, userUnit }) => {
	const Unit = convertTemperatureUnit(userUnit);
	const [tempuratre, setTempuratre] = useState('-');

	useEffect(() => {
		setTempuratre(convertTemperatureValue(rigTemp, rigUnit, userUnit));
	}, []);

	// Default to Fahrenheit if no conversion is needed
	return (
		<>
			{tempuratre} {Unit}
		</>
	);
};

export default React.memo(RigTemperatureConverter);
