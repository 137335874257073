import Logo from 'assets/icons/new-logo.svg';
import Illustration from 'assets/img/404.svg';
import { Button, Image } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	.container {
		max-width: 500px;
		text-align: center;
	}
	button.btn {
		background: ${(props) => props.theme.colors.loginPrimary};
		padding: 10px 30px;
		height: 3.5rem;
		border-radius: 8px;
		border: none;
		font-weight: 500;
		font-size: 1rem;
	}
`;

function Page404() {
	return (
		<Wrapper className="static flex flex-col items-start justify-start object-contain gap-2 mt-10 md:mt-2 md:items-center md:justify-center">
			<Link to="/dashboard">
				<Image
					src={Logo}
					alt="RigsEye"
					className="items-center justify-center h-12 md:absolute left-24 sm:left-28 md:items-start md:justify-start md:left-20 place-content-start md:top-10"
				/>
			</Link>
			<div className="mt-36 h-fit md:mt-0">
				<Image src={Illustration} alt="404 Not Found" title="404" />
				<div className="relative flex items-center content-center justify-center w-full">
					<Link to="/dashboard" className="absolute group -top-12">
						<Button
							type="button"
							className="flex items-center gap-2 text-white "
						>
							<BsArrowLeft
								strokeWidth={1}
								className="w-5 h-5 duration-500 group-hover:-translate-x-2 "
							/>
							Back to home
						</Button>
					</Link>
				</div>
			</div>
		</Wrapper>
	);
}

export default Page404;
