import { kmhToMph, mphToKmh } from 'helpers/utils/unit-customization';
import React, { useEffect, useState } from 'react';

/**
 * Converts the user's preferred unit to the corresponding speed unit.
 *
 * @param {string} userUnit - The user's preferred unit ('kilometers' or 'miles').
 * @returns {string} - The speed unit corresponding to the user's preferred unit ('KM/H' or 'MPH').
 */
export const convertSpeedUnit = (userUnit): string =>
	userUnit === 'kilometers' ? 'KM/H' : 'MPH';

/**
 * Converts the speed value from one unit to another based on the user's preferred unit.
 *
 * @param {string} rigSpeed - The current speed of the rig.
 * @param {string} rigUnit - The unit of the rig's speed (either 'KPH' or 'MPH').
 * @param {string} userUnit - The user's preferred unit ('kilometers' or 'miles').
 * @returns {string} - The converted rig speed in the user's preferred unit.
 */
export const convertSpeedValue = (
	rigSpeed: string,
	rigUnit: string,
	userUnit: string,
) => {
	let Speed: any = '0.0';
	// Convert miles to kilometers
	if (userUnit === 'kilometers' && rigUnit === 'MPH') {
		Speed =
			rigSpeed !== '-' ? mphToKmh(parseFloat(rigSpeed)).toFixed(2) : '0.0';
	}
	// No Conversion needed for kilometers to kilometers
	if (userUnit === 'kilometers' && rigUnit === 'KPH') {
		Speed = rigSpeed !== '-' ? parseFloat(rigSpeed).toFixed(2) : '0.0';
	}
	// Convert kilometers to miles
	if (userUnit === 'miles' && rigUnit === 'KPH') {
		Speed =
			rigSpeed !== '-' ? kmhToMph(parseFloat(rigSpeed)).toFixed(2) : '0.0';
	}
	// No Conversion needed for miles to miles
	if (userUnit === 'miles' && rigUnit === 'MPH') {
		Speed = rigSpeed !== '-' ? parseFloat(rigSpeed).toFixed(2) : '0.0';
	}
	return Speed;
};

/**
 * A React component that converts the speed of a rig between kilometers per hour (KM/H) and miles per hour (MPH) based on the user's preferred unit.
 *
 * @param {string} props.rigSpeed - The current speed of the rig.
 * @param {string} props.rigUnit - The unit of the rig's speed (either 'KPH' or 'MPH').
 * @param {string} props.userUnit - The user's preferred unit ('kilometers' or 'miles').
 * @returns {JSX.Element} - The converted rig speed in the user's preferred unit.
 */
const RigSpeedConverter = ({ rigSpeed, rigUnit, userUnit }) => {
	const Unit = userUnit === 'kilometers' ? 'KM/H' : 'MPH';
	const [tempuratre, setTempuratre] = useState('-');

	useEffect(() => {
		setTempuratre(convertSpeedValue(rigSpeed, rigUnit, userUnit));
	}, []);

	// Default to user unit if no conversion is needed
	return (
		<>
			{tempuratre} {Unit}
		</>
	);
};

export default React.memo(RigSpeedConverter);
