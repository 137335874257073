import { Button, Modal, Spinner } from 'react-bootstrap';
import { HiShieldExclamation } from 'react-icons/hi';

const ForceLoginConfirmModel = ({
	show,
	setShow,
	isLoading,
	handleForceLogin,
}: {
	show: boolean;
	setShow: (flag: boolean) => void;
	isLoading: boolean;
	handleForceLogin: () => void;
}) => {
	const handleClose = () => setShow(false);

	return (
		<Modal centered show={show} onHide={handleClose} className="profile-modal">
			<Modal.Body>
				<div className="space-y-10">
					<div className="information-container">
						<div className="information-container mt-2 text-skin-primary flex flex-col items-center">
							<HiShieldExclamation className="text-7xl mb-2 text-skin-primary" />
							<p className="information font-semibold">Hold On!</p>
						</div>
						<p className="text-xl font-semibold w-full flex text-center">
							You are already logged in on another device. Do you want to log
							out of that device and log in here?
						</p>
					</div>
					<p className="text-sm font-normal italic w-full flex justify-center text-center">
						<span className="font-semibold">Note: </span> There can be only one
						device on which you can log in.
					</p>
					<div className="w-full flex justify-evenly">
						<Button
							variant="bordered-green"
							className="me-3"
							onClick={handleClose}
							disabled={isLoading}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary-green"
							autoFocus
							onClick={handleForceLogin}
							disabled={isLoading}
						>
							Force Login{' '}
							{isLoading && <Spinner animation="border" size="sm" />}
						</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ForceLoginConfirmModel;
