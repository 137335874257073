import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

const CustomTooltip = styled(Tooltip)`
	filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
	transition: opacity 0.2s ease-in-out;

	.tooltip-inner {
		background: #fff;
		border-radius: 8px;
		color: black;
		padding: 0.8rem;
		text-align: left;
	}
	.tooltip-arrow {
		display: none;
	}
`;

function TooltipHandler({
	children,
	content,
	placement = 'top',
	hide = false,
}) {
	const renderTooltip = (props) => (
		<CustomTooltip id="button-tooltip" {...props}>
			{content}
		</CustomTooltip>
	);

	return (
		<OverlayTrigger
			placement={placement || 'top'}
			overlay={hide ? <span /> : renderTooltip}
		>
			{children}
		</OverlayTrigger>
	);
}

export default TooltipHandler;
