import { yupResolver } from '@hookform/resolvers/yup';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import {
	addEquipmentProfile,
	updateEquipmentProfile,
} from 'helpers/api/equipments';
import { useAuth } from 'helpers/contexts/AuthContext';
import useResponsive from 'helpers/hooks/useResponsive';
import { useEffect, useState } from 'react';
import { Button, Form, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Footer, Wrapper } from 'style/addEq.styled';
import * as yup from 'yup';
import Information from './Information';
import Setup from './Setup';

const RIGSCHEMA = {
	equipType: yup.string().default('rig'),
	serial: yup
		.string()
		.required()
		.matches(
			/^[a-zA-Z0-9]+$/,
			'The serial field cannot contain white space and special character',
		),
	subscriptionInfo: yup.string(),
	flow_unit: yup.string(),
	VIN: yup.string(),
	State: yup.string(),
	StateReg: yup.string(),
	Registration: yup.string(),
	LicPlate: yup.string(),
	EngineMake: yup.string(),
	EngineModel: yup.string(),
	EngineRating: yup.string(),
	EngineSerial: yup.string(),
	Transmission: yup.string(),
	yard: yup.number(),
	Make: yup.string(),
	Model: yup.string(),
	CapacityRating: yup.string(),
	GVWR: yup.string(),
	MastMake: yup.string(),
	MastModel: yup.string(),
	MastHeight: yup.string(),
	MastRating: yup.string(),
	DrawworksMake: yup.string(),
	PartsOfLine: yup.string(),
	EncoderType: yup.string(),
	COFOType: yup.string(),
	TopDrive: yup.string(),
	weight_source: yup.string(),
	dacpu: yup.string(),
	daengine: yup.string(),
	dawifi: yup.string(),
	auxacpu: yup.string(),
	auxbcpu: yup.string(),
	camId: yup.string(),
	camSoftware: yup.string(),
	service_provider: yup.string(),
	cell_number: yup.string(),
	meid: yup.string(),
	da_boxnum: yup.string(),
	aux_a_num: yup.string(),
	aux_b_num: yup.string(),
	aux_a_boxnum: yup.string(),
	aux_b_boxnum: yup.string(),
	tong_max_psi: yup.number(),
	tong_max_torque: yup.string(),
	block_travel: yup
		.number()
		.min(0.0, 'Minimum value should be 0.0')
		.max(1.0, 'Maximum value should be 1.0'),
	weight_max_psi: yup.number(),
	max_weight: yup.number(),
	driller_side: yup.number(),
	offdriller_side: yup.number(),
	airp_psi: yup.number(),
	pipe_max_psi: yup.number(),
	tong_max_torque_low: yup.number(),
	tong_max_torque_high: yup.number(),
	well_outlet_psi: yup.number(),
	well_outlet_flow: yup.number(),
	CycleSettingLower: yup.number(),
	CycleSettingUpper: yup.number(),
	zero_well_inlet: yup.number(),
	zero_well_outlet: yup.number(),
	bew: yup.number(),
	wob: yup.number(),
	woh: yup.number(),
	zero_airp_psi: yup.number(),
	well_inlet_psi_u1: yup.number(),
	well_inlet_flow_u1: yup.number(),
	well_outlet_psi_u1: yup.number(),
	well_outlet_flow_u1: yup.number(),
	zero_well_inlet_u1: yup.number(),
	zero_well_outlet_u1: yup.number(),
	chart_rig_max_weight: yup.number(),
	chart_rig_max_rpm: yup.number(),
	chart_rig_block_position: yup.number(),
	chart_rig_pump_flowrate: yup.number(),
	chart_rig_pump_discharge: yup.number(),
	chart_rig_well_flowrate: yup.number(),
	chart_rig_well_discharge: yup.number(),
	chart_rig_max_bpm: yup.number(),
	chart_rig_max_gpm: yup.number(),
};

export default function AddRig({ edit = false, data: equipmentData }) {
	const navigate = useNavigate();

	const [key, setKey] = useState('eq_info');
	const [isLoading, setIsLoading] = useState(false);
	const { user } = useAuth();
	const { isTablet, isMobile } = useResponsive();
	const schema = yup
		.object({
			rigcid: yup.number().default(user?.company),
			...RIGSCHEMA,
		})
		.required();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleRequest = async (data) => {
		try {
			setIsLoading(true);
			toast.loading('Saving...');
			const weightMax =
				data?.max_weight > 999 ? data?.max_weight : data?.max_weight * 1000;
			const promise = edit
				? await updateEquipmentProfile({
						...data,
						weight_max: weightMax,
						type: 'rig',
					})
				: await addEquipmentProfile({
						...data,
						weight_max: weightMax,
						type: 'rig',
					});

			toast.dismiss(); // Remove all existing toasts
			if (promise.success) {
				toast.success(promise.message);
				navigate('/equipments');
			} else {
				toast.error(promise.message);
			}
		} catch (err) {
			toast.dismiss(); // Remove all existing toasts
			toast.error(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	// Load the form data with if it's in edit mode
	useEffect(() => {
		if (edit === true && equipmentData) {
			reset({
				serial: equipmentData?.serial,
				equipType: equipmentData?.type,
				subscriptiontype: equipmentData?.subscription,
				flow_unit: equipmentData?.flow_unit,
				VIN: equipmentData?.equipment_info?.VIN,
				State: equipmentData?.equipment_info?.State,
				StateReg: equipmentData?.equipment_info?.StateReg,
				Registration: equipmentData?.equipment_info?.Registration,
				LicPlate: equipmentData?.equipment_info?.LicPlate,
				EngineMake: equipmentData?.equipment_info?.EngineMake,
				EngineModel: equipmentData?.equipment_info?.EngineModel,
				EngineRating: equipmentData?.equipment_info?.EngineRating,
				EngineSerial: equipmentData?.equipment_info?.EngineSerial,
				Transmission: equipmentData?.equipment_info?.Transmission,
				yard: equipmentData?.yard,
				Make: equipmentData?.equipment_info?.Make,
				Model: equipmentData?.equipment_info?.Model,
				CapacityRating: equipmentData?.equipment_info?.CapacityRating,
				GVWR: equipmentData?.equipment_info?.GVWR,
				MastMake: equipmentData?.equipment_info?.MastMake || '',
				MastModel: equipmentData?.equipment_info?.MastModel || '',
				MastHeight: equipmentData?.equipment_info?.MastHeight,
				MastRating: equipmentData?.equipment_info?.MastRating,
				DrawworksMake: equipmentData?.equipment_info?.DrawworksMake,
				PartsOfLine: equipmentData?.equipment_info?.PartsOfLine,
				EncoderType: equipmentData?.equipment_info?.EncoderType,
				COFOType: equipmentData?.equipment_info?.COFOType,
				TopDrive: equipmentData?.equipment_info?.TopDrive,
				weight_source: equipmentData?.weight_source,
				dacpu: equipmentData?.software_info?.dacpu,
				daengine: equipmentData?.software_info?.daengine,
				dawifi: equipmentData?.software_info?.dawifi,
				auxacpu: equipmentData?.software_info?.auxacpu,
				auxbcpu: equipmentData?.software_info?.auxbcpu,
				service_provider: equipmentData?.cell_info?.service_provider,
				cell_number: equipmentData?.cell_info?.cell_number,
				meid: equipmentData?.cell_info?.meid,
				da_boxnum: equipmentData?.cell_info?.da_boxnum,
				aux_a_boxnum: equipmentData?.cell_info?.aux_a_boxnum,
				aux_b_boxnum: equipmentData?.cell_info?.aux_b_boxnum,
				tong_max_psi: equipmentData?.rig_values?.tong_max_psi,
				tong_max_torque_high: equipmentData?.rig_values?.tong_max_torque_high,
				block_travel: equipmentData?.rig_values?.block_travel,
				weight_max_psi: equipmentData?.rig_values?.weight_max_psi,
				max_weight:
					parseInt(equipmentData?.rig_values?.max_weight, 10) > 999
						? parseInt(equipmentData?.rig_values?.max_weight, 10) / 1000
						: parseInt(equipmentData?.rig_values?.max_weight, 10),
				// max_weight: equipmentData?.rig_values?.max_weight,
				driller_side: equipmentData?.rig_values?.driller_side,
				offdriller_side: equipmentData?.rig_values?.offdriller_side,
				airp_psi: equipmentData?.rig_values?.airp_psi,
				pipe_max_psi: equipmentData?.rig_values?.pipe_max_psi,
				// tong_max_torque: equipmentData?.rig_values?.tong_max_torque, missing from fields
				tong_max_torque_low: equipmentData?.rig_values?.tong_max_torque_low,
				tong_max_torque_low_high:
					equipmentData?.rig_values?.tong_max_torque_low_high,
				well_outlet_psi: equipmentData?.rig_values?.well_outlet_psi,
				well_outlet_flow: equipmentData?.rig_values?.well_outlet_flow,
				CycleSettingLower: equipmentData?.equipment_info?.CycleSettingLower,
				CycleSettingUpper: equipmentData?.equipment_info?.CycleSettingUpper,
				zero_well_inlet: equipmentData?.rig_values?.zero_well_inlet,
				zero_well_outlet: equipmentData?.rig_values?.zero_well_outlet,
				bew: equipmentData?.rig_values?.bew,
				wob: equipmentData?.rig_values?.wob,
				woh: equipmentData?.rig_values?.woh,
				zero_airp_psi: equipmentData?.rig_values?.zero_airp_psi,
				well_inlet_psi_u1: equipmentData?.rig_values?.well_inlet_psi_u1,
				well_inlet_flow_u1: equipmentData?.rig_values?.well_inlet_flow_u1,
				well_outlet_psi_u1: equipmentData?.rig_values?.well_outlet_psi_u1,
				well_outlet_flow_u1: equipmentData?.rig_values?.well_outlet_flow_u1,
				zero_well_inlet_u1: equipmentData?.rig_values?.zero_well_inlet_u1,
				zero_well_outlet_u1: equipmentData?.rig_values?.zero_well_outlet_u1,
				chart_rig_max_weight: equipmentData?.rig_values?.chart_rig_max_weight,
				chart_rig_max_rpm: equipmentData?.rig_values?.chart_rig_max_rpm,
				chart_rig_block_position:
					equipmentData?.rig_values?.chart_rig_block_position,
				chart_rig_pump_flowrate:
					equipmentData?.rig_values?.chart_rig_pump_flowrate,
				chart_rig_pump_discharge:
					equipmentData?.rig_values?.chart_rig_pump_discharge,
				chart_rig_well_flowrate:
					equipmentData?.rig_values?.chart_rig_well_flowrate,
				chart_rig_well_discharge:
					equipmentData?.rig_values?.chart_rig_well_discharge,
				chart_rig_max_bpm: equipmentData?.rig_values?.chart_rig_max_bpm,
				chart_rig_max_gpm: equipmentData?.rig_values?.chart_rig_max_gpm,
			});
		}
	}, [edit, equipmentData]);

	// if edit and no data show loading
	if (edit && !equipmentData) {
		return (
			<div className="mt-5 text-center">
				<Spinner animation="border" size="md" />
			</div>
		);
	}

	return (
		<DashboardLayout>
			<Wrapper isMobile={isMobile} isTablet={isTablet}>
				<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3">
					<Tab eventKey="eq_info" title="EQUIPMENT INFORMATION" />
					<Tab eventKey="rig_setup" title="RIG SETUP" />
				</Tabs>
				<Form onSubmit={handleSubmit(handleRequest)}>
					{key === 'eq_info' ? (
						<Information register={register} errors={errors} />
					) : (
						<Setup register={register} errors={errors} />
					)}
					<Footer>
						<Button
							variant="primary-green"
							className="float-end"
							type="submit"
							disabled={isLoading}
						>
							{edit ? 'Save' : 'Create'}
							<Spinner
								size="sm"
								animation="border"
								className="ms-2"
								hidden={!isLoading}
							/>
						</Button>
					</Footer>
				</Form>
			</Wrapper>
		</DashboardLayout>
	);
}
