export const WEIGHT_GAUGE_100 = 100;
export const WEIGHT_GAUGE_180 = 180;
export const WEIGHT_GAUGE_210 = 210;
export const WEIGHT_GAUGE_240 = 240;
export const WEIGHT_GAUGE_300 = 300;

export const WEIGHT_MAX_VALUES = [
	WEIGHT_GAUGE_100,
	WEIGHT_GAUGE_180,
	WEIGHT_GAUGE_210,
	WEIGHT_GAUGE_240,
	WEIGHT_GAUGE_300,
];

export const WEIGHT_GAUGE_MAX_ROTATION = 275;

export const WEIGHT_GAUGE_DEFAULT_MAX_VALUE = WEIGHT_GAUGE_300;
export const WEIGHT_GAUGE_MAX_VALUE_240 = WEIGHT_GAUGE_240;
export const WEIGHT_GAUGE_100_IMG = '/images/weight-gauge-100.svg';
export const WEIGHT_GAUGE_180_IMG = '/images/weight-gauge-180.svg';
export const WEIGHT_GAUGE_210_IMG = '/images/weight-gauge-210.svg';
export const WEIGHT_GAUGE_240_IMG = '/images/weight-gauge-240.svg';
export const WEIGHT_GAUGE_300_IMG = '/images/weight-gauge-300.svg';
export const WEIGHT_GAUGE_RED_LINE_IMG = '/images/line-red.png';
export const WEIGHT_GAUGE_GREEN_LINE_IMG = '/images/line-green.png';

/**
 * Calculates the weight on hook based on the actual load and the rig's basic empty weight (BEW).
 *
 * @param actualLoad - The actual load being lifted, as a string.
 * @param rig_bew - The rig's basic empty weight (BEW), as a string.
 * @returns The calculated weight on hook, capped at a maximum of 300.
 */
export const calculateWeightOnHook = (actualLoad: any, rigBew: any) => {
	const weight = parseInt(actualLoad, 10);
	const weightOnHook = weight - parseInt(rigBew, 10);

	// Set min value to 0
	if (weightOnHook < 0) return 0;
	// Set max value to 300
	if (weight > 300) return 300;
	return weightOnHook;
};
