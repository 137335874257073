import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import Button from 'components/Button';
import FormError from 'components/FormError';
import { editUser, getUserByID } from 'helpers/api/userManagement';
import mobileProviders from 'helpers/const/mobileProviders.json';
import { useAuth } from 'helpers/contexts/AuthContext';
import { ADD_USER_MUTATION } from 'helpers/queries/userQuery';
import { getToken } from 'helpers/utils/auth-provider';
import { formatPhoneNumber } from 'helpers/utils/formatters';
import UserChangePassword from 'pages/usermanagement/UserChangePassword';
import { useEffect, useState, useTransition } from 'react';
import { Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import Assignments from './Assignments';

const FormWrapper = styled(Form)`
	.password-view {
		input {
			border-radius: 4px !important;
		}
		.password-show-button {
			position: absolute;
			right: 5%;
			top: 0;
			bottom: 0;
		}
	}
`;

YupPassword(yup); // extend yup

const PERMISSIONS = [
	{ value: 2, name: 'Normal' },
	{ value: 3, name: 'Manager' },
	{ value: 4, name: 'Admin' },
	{ value: 5, name: 'Rigseye Admin' },
	{ value: 6, name: 'Developer' },
];

export default function UserForm({ update, show, handleClose, edit, person }) {
	const { user } = useAuth();
	const [isLoadingUser, setIsLoadingUser] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [passwordShown, setPasswordShown] = useState(false);
	const [pageAccess, setPageAccess] = useState([]);
	const [notificationAccess, setNotificationAccess] = useState([]);
	const [mobileNumber, setMobileNumber] = useState('');
	const [passwordModal, setPasswordModal] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);
	const [, startTransition] = useTransition();

	const handleOpenPasswordModal = () => {
		setPasswordModal(true);
	};
	const handleClosePasswordModal = () => {
		setPasswordModal(false);
	};

	const schema = yup
		.object({
			username: yup.string().required('Username is required'),
			firstName: yup.string().required('First name is required.'),
			middleName: yup.string(),
			lastName: yup.string().required('Last name is required'),
			email: yup.string().required('Email is required'),
			mobileNumber: yup.string(),
			mobileProvider: yup.string(),
			uroleid: yup.number(),
			// company: yup.string().default(user.company),
			downloads: yup.string().default('N'),
			supervisor: yup.string().default('N'),
			temperatureUnit: yup.string().default('fahrenheit'),
			companyUser: yup.string().default('N'),
			speedUnit: yup.string().default('miles'),
		})
		.required();

	const { register, handleSubmit, formState, reset, setValue } = useForm({
		resolver: yupResolver(
			person === undefined
				? schema.shape({
						password: yup.string().required('Please enter the password.'),
					})
				: schema,
		),
	});

	const handlePageAccess = (e) => {
		const { value } = e.target;
		// Add to page access if not already in array
		if (!pageAccess.includes(value)) {
			setPageAccess([...pageAccess, value]);
		}
		// Remove from page access if already in array
		else {
			setPageAccess(pageAccess.filter((item) => item !== value));
		}
	};
	const handleNotificationAccess = (e) => {
		const { value } = e.target;
		// Add to notification access if not already in array
		if (!notificationAccess.includes(value)) {
			setNotificationAccess([...notificationAccess, value]);
		}
		// Remove from notification access if already in array
		else {
			setNotificationAccess(
				notificationAccess.filter((item) => item !== value),
			);
		}
	};

	// Edit handlers
	const handleMobileProvider = () => {
		const i = mobileProviders.filter(
			(item) => item.mpname === currentUser?.mobileprovider,
		);
		return i?.[0]?.mpid;
	};

	const [addUser] = useMutation(ADD_USER_MUTATION, {
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

	useEffect(() => {
		async function fetchUserByID() {
			if (person && person.userid) {
				setIsLoadingUser(true);
				await getUserByID(person?.userid)
					.then((res) => {
						setCurrentUser(res.data.user);
						startTransition(() => {
							setPageAccess(
								JSON.parse(
									res.data.user.upage_access
										? res.data.user.upage_access
										: '[]',
								),
							);
							setNotificationAccess(
								JSON.parse(
									res.data.user.notifications
										? res.data.user.notifications
										: '[]',
								),
							);
							setMobileNumber(res.data.user.mobile);
							reset({
								userId: res.data.user.id,
								username: res.data.user.username,
								firstName: res.data.user.name,
								middleName: res.data.user.middlename,
								lastName: res.data.user.lastname,
								email: res.data.user.email,
								mobileNumber: res.data.user.mobile,
								mobileProvider: handleMobileProvider(),
								uroleid: res.data.user.uroleid,
								downloads: res.data.user.downloads,
								companyUser: res.data.user.companyUser,
								supervisor: res.data.user.supervisor,
								password: yup
									.string()
									.password()
									.required('Password is required'),
								temperatureUnit: res.data.user.temperatureUnit,
								speedUnit: res.data.user.speedUnit,
							});
						});
					})
					.catch((err) => {
						toast.error(err.message);
					})
					.finally(() => {
						setIsLoadingUser(false);
					});
			}
		}
		fetchUserByID();
	}, [person, show]);

	const onSubmit = async (data) => {
		setIsLoading(true);

		const userData = {
			...data,
			notification_option: JSON.stringify(notificationAccess),
			upage_access: pageAccess.length > 0 ? JSON.stringify(pageAccess) : '[]',
		};
		try {
			if (edit) {
				const { errors: editErrors } = await editUser(userData, person.userid);
				if (editErrors && editErrors.length > 0) {
					toast.error(editErrors[0].message);
				}
			} else {
				const { errors: addErrors } = await addUser({
					variables: {
						...userData,
					},
				});
				if (addErrors && addErrors.length > 0) {
					toast.error(addErrors[0].message);
				}
			}

			await update();
			toast.success(`User ${edit ? 'updated' : 'created'} successfully!`);
			handleClose();
		} catch (error) {
			setIsLoading(false);
			if (
				error.toString().indexOf('Duplicate entry') !== -1 &&
				error.toString().indexOf("key 'uemail'") !== -1
			) {
				toast.error('Email already exists!');
			}
			toast.error(error.message || 'Something went wrong!');
		} finally {
			setIsLoading(false);
		}
	};

	const handlePasswordShow = () => {
		setPasswordShown(!passwordShown);
	};

	// Phone number formatting
	const handleMobileNumberChange = (e) => {
		const { value } = e.target;
		const getSimplePhoneNumber = (number = '') => number.replace(/\D+/g, '');
		const phone = getSimplePhoneNumber(value);
		setValue('mobileNumber', phone);
		setMobileNumber(value);
	};

	const { errors } = formState;

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={show}
			onHide={handleClose}
			className="p-0 profile-modal"
		>
			<Modal.Body>
				<div className="information-container">
					<span className="information">User Profile</span>
				</div>

				{isLoadingUser ? (
					<div className="flex flex-col items-center justify-center w-full gap-4 text-lg font-bold h-96">
						<Spinner animation="border" className="mt-4" />
						<p>Loading user details, Please wait.</p>
					</div>
				) : (
					<>
						<FormWrapper onSubmit={handleSubmit(onSubmit)}>
							<Row className="inputs">
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="username">
										<Form.Label>Username</Form.Label>
										<Form.Control
											type="text"
											name="username"
											autoComplete="new-username"
											{...register('username')}
										/>
										<FormError>{errors.username?.message}</FormError>
									</Form.Group>
								</Col>

								{!currentUser && (
									<Col md={6} sm={12} className="mb-3">
										<Form.Group controlId="password">
											<Form.Label>Password</Form.Label>
											<InputGroup className="password-view">
												<Form.Control
													type={passwordShown ? 'text' : 'password'}
													name="password"
													autoComplete="new-password"
													{...register('password')}
												/>
												<Button
													variant="transparent-green"
													id="button-addon2"
													onClick={handlePasswordShow}
													className="password-show-button"
												>
													<EyeIcon className="password-show" />
												</Button>
											</InputGroup>
											<FormError>{errors.password?.message}</FormError>
										</Form.Group>
									</Col>
								)}
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="firstName">
										<Form.Label>First Name</Form.Label>
										<Form.Control
											type="text"
											name="firstName"
											{...register('firstName')}
										/>
										<FormError>{errors.firstName?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="middleName">
										<Form.Label>Middle Name</Form.Label>
										<Form.Control
											type="text"
											name="middleName"
											{...register('middleName')}
										/>
										<FormError>{errors.middleName?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="lastName">
										<Form.Label>Last Name</Form.Label>
										<Form.Control
											type="text"
											name="lastName"
											{...register('lastName')}
										/>
										<FormError>{errors.lastName?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="email">
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											name="email"
											{...register('email')}
										/>
										<FormError>{errors.email?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="mobileNumber">
										<Form.Label>Mobile Number (USA & Canada only)*</Form.Label>
										<Form.Control
											type="tel"
											value={formatPhoneNumber(mobileNumber)}
											onChange={handleMobileNumberChange}
											maxLength="10"
											name="mobileNumber"
										/>
										<FormError>{errors.mobileNumber?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="mobileProvider">
										<Form.Label>Mobile Provider</Form.Label>
										<Form.Control
											as="select"
											name="mobileProvider"
											defaultValue={parseInt(currentUser?.mobileprovider, 10)}
											{...register('mobileProvider')}
										>
											{mobileProviders.map((provider) => (
												<option value={provider.mpid} key={provider.mpid}>
													{provider.mpname}
												</option>
											))}
										</Form.Control>
										<FormError>{errors.mobileProvider?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="uroleid">
										<Form.Label>Permission Level*</Form.Label>
										<Form.Control
											as="select"
											name="uroleid"
											defaultValue={user.role}
											{...register('uroleid')}
										>
											{PERMISSIONS.map((permission) => {
												if (permission.value <= user.role) {
													return (
														<option
															value={permission.value}
															key={permission.value}
														>
															{permission.name}
														</option>
													);
												}
												return null;
											})}
										</Form.Control>
										<FormError>{errors.permission?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="downloads">
										<Form.Label>Download Project Data*</Form.Label>
										<Form.Control
											as="select"
											name="downloads"
											{...register('downloads')}
											defaultValue="N"
										>
											<option value="Y">Yes</option>
											<option value="N">No</option>
										</Form.Control>
										<FormError>{errors.downloads?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-2">
									<Form.Group controlId="supervisor">
										<Form.Label>Supervisor*</Form.Label>
										<Form.Control
											as="select"
											name="supervisor"
											{...register('supervisor')}
											defaultValue="N"
										>
											<option value="">Select a Supervisor</option>
											<option value="Y">Yes</option>
											<option value="N">No</option>
										</Form.Control>
										<FormError>{errors.supervisor?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-2">
									<Form.Group controlId="companyUser">
										<Form.Label>Company User*</Form.Label>
										<Form.Control
											as="select"
											name="companyUser"
											{...register('companyUser')}
											defaultValue="N"
										>
											<option value="Y">Yes</option>
											<option value="N">No</option>
										</Form.Control>
										<FormError>{errors.companyUser?.message}</FormError>
									</Form.Group>
								</Col>
							</Row>

							<div className="mt-2 mb-4">
								<h4>Report Settings</h4>
							</div>

							<Row>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="temperatureUnit">
										<Form.Label>Temperature Unit</Form.Label>
										<Form.Control
											as="select"
											name="temperatureUnit"
											{...register('temperatureUnit')}
										>
											<option value="fahrenheit">Fahrenheit</option>
											<option value="celsius">Celsius</option>
										</Form.Control>
										<FormError>{errors.temperatureUnit?.message}</FormError>
									</Form.Group>
								</Col>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group controlId="speedUnit">
										<Form.Label>Speed Unit</Form.Label>
										<Form.Control
											as="select"
											name="speedUnit"
											{...register('speedUnit')}
										>
											<option value="miles">Miles Per Hour (mph)</option>
											<option value="kilometers">
												Kilometers per Hour (km / hour)
											</option>
										</Form.Control>
										<FormError>{errors.temperatureUnit?.message}</FormError>
									</Form.Group>
								</Col>
							</Row>

							<div className="mt-2 mb-4">
								<h4>Page Access</h4>
							</div>

							<div className="gap-1 mb-4 checkbox-container">
								<label htmlFor="rig" className="cursor-pointer">
									<input
										type="checkbox"
										id="rig"
										value="rig"
										onChange={handlePageAccess}
										defaultChecked={currentUser?.access?.includes('rig')}
									/>
									<span className="checkmark" />
									<span className="ms-35">Rig Details</span>
								</label>

								<label htmlFor="drilling" className="cursor-pointer">
									<input
										type="checkbox"
										id="drilling"
										value="drilling"
										onChange={handlePageAccess}
										defaultChecked={currentUser?.access?.includes('drilling')}
									/>
									<span className="checkmark" />
									<span className="ms-35">Activity</span>
								</label>

								<label htmlFor="can" className="cursor-pointer">
									<input
										type="checkbox"
										id="can"
										value="can"
										onChange={handlePageAccess}
										defaultChecked={currentUser?.access?.includes('can')}
									/>
									<span className="checkmark" />
									<span className="ms-35">Engine</span>
								</label>

								<label htmlFor="cam" className="cursor-pointer">
									<input
										type="checkbox"
										id="cam"
										value="cam"
										onChange={handlePageAccess}
										defaultChecked={currentUser?.access?.includes('cam')}
									/>
									<span className="checkmark" />
									<span className="ms-35">Cameras</span>
								</label>

								<label htmlFor="docsys" className="cursor-pointer">
									<input
										type="checkbox"
										id="docsys"
										value="docsys"
										onChange={handlePageAccess}
										defaultChecked={currentUser?.access?.includes('docsys')}
									/>
									<span className="checkmark" />
									<span className="ms-35">DocSys</span>
								</label>
							</div>

							<div className="mb-4">
								<h4>Notification Option</h4>
							</div>

							<div className="gap-1 mb-4 checkbox-container">
								<label htmlFor="email_notification" className="cursor-pointer">
									<input
										type="checkbox"
										id="email_notification"
										value="email_notification"
										onChange={handleNotificationAccess}
										defaultChecked={currentUser?.notifications?.includes(
											'email',
										)}
									/>
									<span className="checkmark" />
									<span className="ms-35">Email</span>
								</label>

								<label htmlFor="phone_notification" className="cursor-pointer">
									<input
										type="checkbox"
										id="phone_notification"
										value="phone_notification"
										onChange={handleNotificationAccess}
										defaultChecked={currentUser?.notifications?.includes(
											'phone',
										)}
									/>
									<span className="checkmark" />
									<span className="ms-35">Phone</span>
								</label>

								<label htmlFor="push_notification" className="cursor-pointer">
									<input
										type="checkbox"
										id="push_notification"
										value="push_notification"
										onChange={handleNotificationAccess}
										defaultChecked={currentUser?.notifications?.includes(
											'push',
										)}
									/>
									<span className="checkmark" />
									<span className="ms-35">Push Notification</span>
								</label>
							</div>

							{!edit ? (
								<div className="flex gap-2 float-end">
									<Button
										type="button"
										variant="secondary"
										onClick={handleClose}
									>
										Close
									</Button>

									<Button type="submit" variant="primary" disabled={isLoading}>
										{isLoading ? (
											<Spinner size="sm" animation="border" />
										) : (
											'Save'
										)}
									</Button>
								</div>
							) : (
								<div className="flex gap-2 float-end">
									<Button variant="primary" onClick={handleOpenPasswordModal}>
										Change Password
									</Button>
									<Assignments
										user={currentUser}
										setUser={setCurrentUser}
										update={update}
									/>
									<Button
										type="button"
										variant="secondary"
										onClick={handleClose}
									>
										Close
									</Button>
									<Button
										type="submit"
										variant="primary"
										disabled={isLoading}
										style={{ minWidth: 100 }}
									>
										{isLoading ? (
											<Spinner size="sm" animation="border" />
										) : (
											'Save'
										)}
									</Button>
								</div>
							)}
						</FormWrapper>
						{/**
						 * Put the user update password component out side of parent form.
						 * Otherwise parent form will be auto submitted twice.
						 */}
						{person?.userid && (
							<UserChangePassword
								uid={person.userid}
								show={passwordModal}
								handleClose={handleClosePasswordModal}
							/>
						)}
					</>
				)}
			</Modal.Body>
		</Modal>
	);
}
